import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Inject,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { KzPopup } from './kz-popup';

@Injectable()
export class KzPopupService {
  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private _document: Document,
  ) {
  }

  create<T>(component: Type<T>, container?: Node): KzPopup<T> {
    return new KzPopup<T>(
      component,
      container || this._document.body,
      this._componentFactoryResolver,
      this._injector,
      this._applicationRef,
      this._document,
    );
  }
}

// import { DOCUMENT } from '@angular/common';
// import {
//   ApplicationRef,
//   Inject,
//   Injectable, Injector,
//   Type,
//   ViewContainerRef,
// } from '@angular/core';
// import { KzPopup } from './kz-popup';
//
// @Injectable()
// export class KzPopupService {
//   constructor(
//     // // private _componentFactoryResolver: ComponentFactoryResolver,
//     private _injector: Injector,
//     private _viewContainerRef: ViewContainerRef,
//     private _applicationRef: ApplicationRef,
//     @Inject(DOCUMENT) private _document: Document,
//   ) {
//   }
//
//   create<T>(component: Type<T>, container?: Node): KzPopup<T> {
//     console.log('KzPopupService.create()', component, container, /*this._viewContainerRef,*/ this._applicationRef, this._document );
//     return new KzPopup<T>(
//       component,
//       container || this._document.body,
//       this._viewContainerRef,
//       this._injector,
//       this._applicationRef,
//       this._document,
//     );
//   }
// }
