import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChange,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  ImageResizeDensity,
  ImageResizeFormat,
  ImageResizeGravity,
  ImageResizeService,
  ImageResizeType,
  Preset,
} from '../image-resize.service';
import { SeoService } from '../../../services/utilities/seo.service';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-picture-source',
  templateUrl: './picture-source.component.html',
  styleUrls: ['./picture-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PictureSourceComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() imgSrc: string;
  @Input() presets: Preset | Array<Preset>;
  @Input() width;
  @Input() height;
  @Input() alt: string;
  @Input() caption: string | TemplateRef<any>;
  @Input() useWebp = true;
  @Input() useLazyLoad = true;
  @Input() fetchPriority = false;
  @Input() bucket = null;
  @Input() resizeType: ImageResizeType; // 'fit' | 'fill' | 'auto'
  @Input() resizeGravity: ImageResizeGravity; // 'no' | 'so' | 'ea' | 'we' | 'noea' | 'nowe' | 'soea' | 'sowe' | 'ce' | 'sm' | 'fp:%x:%y'
  @Input() resizeFormat: ImageResizeFormat; // 'jpg' | 'webp' | 'png'
  // mediaQueries should respect min-width and max-width pattern, especially if the image prelaod is activated. Eg:
  // [
  //  {mediaQuery: '(max-width: 400px)', settings: presetUserAvatarXs},
  //  {mediaQuery: '(min-width: 401px) and (max-width: 875px)', settings: presetUserAvatarSm}
  // ]
  @Input() mediaQueries: Array<MediaQuery> = [];
  @Input() handleX2 = false;
  @Input() handleX3 = false;
  @Input() preloadImages = false;
  @Input() loader = false;
  @HostBinding('class.imgFit') @Input() imgFit = false;
  @HostBinding('class.imgFitHeight') @Input() imgFitHeight = false;
  @HostBinding('class.bgBlur') @Input() bgBlur = false;

  public imgUrl: string;
  public imgDensityUrl: string;
  public imgWebpUrl: string;
  public imgExtension: string;
  public isReady = false;
  public mediaQueriesImg: Array<PictureMediaQuery>;
  public stringCaption: string;
  private defaultMediaQuery: string;
  public style$: BehaviorSubject<{'padding-bottom': string, height: string}> = new BehaviorSubject(undefined);
  public isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild('vc', {read: ViewContainerRef}) vc: ViewContainerRef;

  constructor(
      private readonly seoService: SeoService,
      private readonly cdr: ChangeDetectorRef,
      private readonly renderer: Renderer2,
  ) { }

  ngAfterViewInit() {
    if (this.caption && this.caption instanceof TemplateRef) {
        this.vc.createEmbeddedView(this.caption);
    }
  }

  ngOnInit(): void {
    if (this.width && this.height) {
      this.style$.next({'padding-bottom': `${this.height / this.width * 100}%`, height: '0'});
    }

    this.stringCaption = this.caption && typeof this.caption === 'string' ? this.caption : undefined;

    if (this.preloadImages && this.mediaQueries.length) {
      const max: MediaQuery = this.mediaQueries.reduce((prev: MediaQuery, curr: MediaQuery) => {
        const prevObj = new MediaQuery(prev.mediaQuery, prev.settings);
        const currObj = new MediaQuery(curr.mediaQuery, curr.settings);
        return prevObj.mediaQueryPixelValue > currObj.mediaQueryPixelValue ? prevObj : currObj;
      });
      const maxObj = new MediaQuery(max.mediaQuery, max.settings);
      this.defaultMediaQuery = maxObj.mediaQueryPixelValue ? `(min-width: ${maxObj.mediaQueryPixelValue + 1}px)` : undefined;
    }
    this.setPaths();
  }
  ngOnChanges(changes: { [key: string]: SimpleChange }): void {
    if (changes.imgSrc && changes.imgSrc.previousValue !== undefined && !changes.imgSrc.isFirstChange() ) {
      this.isReady = false;
      this.setPaths();
    }
  }

  isLoaded() {
    this.style$.next({'padding-bottom': '0%', height: 'auto'});
    this.isLoaded$.next(true);
    this.cdr.detectChanges();
  }
  setPaths() {
    if (!this.imgSrc) {
      return;
    }
    const densities: Array<ImageResizeDensity> =
        [1, ...(this.handleX2 ? [2 as ImageResizeDensity] : []), ...(this.handleX3 ? [3 as ImageResizeDensity] : []) ];


    let preloadTag;
    let preloadWebPTag;
    // set default img (desktop) : default / density 2x and 3x / wepb
    // IMGURL
    this.imgUrl = `${ImageResizeService.resizeMediaPreset({
      imgPath: this.imgSrc,
      presets: this.presets,
      customSettings: {
        width: this.presets ? null : this.width,
        height: this.presets ? null : this.height, format: this.resizeFormat, type: this.resizeType, gravity: this.resizeGravity
      },
      bucket: this.bucket
    })}`;
    this.imgExtension = PictureMediaQuery.extension(this.imgSrc);
    // preload
    if (this.preloadImages) {
      preloadTag = {rel: 'preload', href: this.imgUrl, as: 'image', type: `image/${this.imgExtension}`, media: this.defaultMediaQuery};
    }
    // IMG DENSITY
    if (densities.length > 1) {
      this.imgDensityUrl = null;
      densities.forEach((density) => {
        const presets = this.presets ? (Array.isArray(this.presets) ? [...this.presets] : [this.presets]) : null;
        if (presets && presets.length > 0) {
          presets[0].density = density;
        }
        this.imgDensityUrl = `${(this.imgDensityUrl) ? this.imgDensityUrl + ', ' : ''}` +
            ImageResizeService.resizeMediaPreset({
              imgPath: this.imgSrc,
              presets,
              customSettings: {
                width: this.presets ? null : this.width, height: this.presets ? null : this.height,
                format: this.resizeFormat, type: this.resizeType, gravity: this.resizeGravity,
                dpr: (!this.presets && density > 1) ? density : undefined
              },
              bucket: this.bucket
            }) + ' ' + density + 'x';
      });
    }

    if (this.preloadImages && this.imgDensityUrl) {
      // preload
      preloadTag.imagesrcset = this.imgDensityUrl;
    }
    // IMG WEBP / DENSITY
    if (this.useWebp) {
      this.imgWebpUrl = null;
      densities.forEach((density) => {
        const presets = this.presets ? (Array.isArray(this.presets) ? [...this.presets] : [this.presets]) : null;
        if (presets && presets.length > 0) {
          presets[0].density = density;
          presets[0].format = 'webp';
        }
        this.imgWebpUrl = `${(this.imgWebpUrl) ? this.imgWebpUrl + ', ' : ''}` +
            ImageResizeService.resizeMediaPreset({
              imgPath: this.imgSrc,
              presets,
              customSettings: {
                width: this.presets ? null : this.width, height: this.presets ? null : this.height,
                format: 'webp', type: this.resizeType, gravity: this.resizeGravity,
                dpr: (!this.presets && density > 1) ? density : undefined
              },
              bucket: this.bucket
            }) + ' ' + density + 'x';
      });
      if (this.preloadImages) {
        preloadWebPTag = {rel: 'preload', /*href: this.imgUrl, */imagesrcset: this.imgWebpUrl, as: 'image',
          type: `image/webp`, media: this.defaultMediaQuery};
      }
    }

    // set mediaQueries img
    this.mediaQueriesImg = this.mediaQueries.reduce((acc: Array<PictureMediaQuery>, mediaQ: MediaQuery) => {
      const pictureMediaQuery = new PictureMediaQuery(this.imgSrc, mediaQ.mediaQuery, mediaQ.settings, this.resizeType,
        this.resizeFormat, this.resizeGravity, densities, mediaQ.specificImgSrc);
      const pictureMediaQueries = [pictureMediaQuery];
      if (this.useWebp) {
        const pictureWebPMediaQuery = new PictureMediaQuery(this.imgSrc, mediaQ.mediaQuery, mediaQ.settings, this.resizeType,
          'webp', this.resizeGravity, densities, mediaQ.specificImgSrc);
        pictureMediaQueries.unshift(pictureWebPMediaQuery);
      }
      return [...acc, ...pictureMediaQueries];
    }, []);

    // PRELOAD TAGS ()
    if (this.preloadImages) {
      const isEnvLocal = (environment.env === 'local');
      // preload default media (if useWebp is setted, only preload webp as browser would preload both webp and jpg in the other case)
      // https://www.bronco.co.uk/our-ideas/using-relpreload-for-responsive-images/
      this.seoService.addTag(this.useWebp ? preloadWebPTag : preloadTag, !isEnvLocal);
      // preload media queries medias
      this.mediaQueriesImg.slice().reverse().forEach((media) => {
        if (this.useWebp && media.format !== 'webp') { return; }
        this.seoService.addTag(
            {rel: 'preload', /*href: this.imgUrl, */imagesrcset:  media.imgSrc, as: 'image',
              type: `image/${media.format}`, media: media.mediaQuery}, !isEnvLocal);
      });
    }
    this.isReady = true;
  }

  trackByDatas = (index, data): number => data ? data.mediaQuery + data.format : undefined;

}

export class MediaQuery {
  mediaQuery: string;
  settings: Preset | Array<Preset> | {width: number, height: number};
  specificImgSrc?: string;

  constructor(
      mediaQuery: string,
      settings: Preset | Array<Preset> | {width: number, height: number},
      specificImgSrc?: string
  ) {
    this.mediaQuery = mediaQuery;
    this.settings = settings;
    this.specificImgSrc = specificImgSrc;
  }

  get mediaQueryPixelValue(): number {
    const groups = /\(\s*?(max)-(width|height)\s*?:\s*?(\d+)(px|em)\s*?\)\s*?/gms.exec(this.mediaQuery);
    return groups[1] && groups[1] === 'max' && groups[2] && groups[2] === 'width' && groups[3] ? parseInt(groups[3], 10) : null;
  }
}

export class PictureMediaQuery extends MediaQuery {
  imgSrc: string;
  format?: ImageResizeFormat;
  private readonly _imgSrcStore?: string;

  constructor(
      imgSrc: string,
      mediaQuery: string,
      settings: Preset | Array<Preset> | {width: number, height: number},
      type?: ImageResizeType,
      format?: ImageResizeFormat,
      gravity?: ImageResizeGravity,
      densities?: Array<ImageResizeDensity>,
      specificImgSrc?: string
  ) {
    super(mediaQuery, settings, specificImgSrc);
    this.imgSrc = imgSrc;
    this._imgSrcStore = this.imgSrc;
    this.format = format || PictureMediaQuery.extension(this._imgSrcStore) || 'png';
    this.setPictureMediaQuery(null, format, null, densities, specificImgSrc);
  }

  static extension(img: string): 'jpg' | 'webp' | 'png' {
    const reg = /(?:\.([^.]+))?$/;
    const ext = reg.exec(img)[1];
    return (ext && (ext === 'jpg' || ext === 'webp' || ext === 'png')) ? ext : undefined;
  }

  setPictureMediaQuery(
      type?: ImageResizeType,
      format?: ImageResizeFormat,
      gravity?: ImageResizeGravity,
      densities?: Array<ImageResizeDensity>,
      specificImgSrc?: string
  ): void {
    let fullPath = '';
    densities = densities || [1];
    densities.forEach((density: ImageResizeDensity, index) => {
      let width;
      let height;
      let presets;
      if (!(this.settings instanceof Preset) && !(this.settings instanceof Array)) {
        width = this.settings.width;
        height = this.settings.height;
      } else {
        presets = this.settings
            ? (Array.isArray(this.settings)
                ? [...this.settings]
                : ((this.settings instanceof Preset) ? [this.settings] : null))
            : null;
        if (presets) {
          if (presets && presets.length > 0) {
            presets[0].density = density;
            presets[0].format = this.format === 'webp' ? 'webp' : null;
          }
        }
      }
      fullPath += `${ImageResizeService.resizeMediaPreset({
        imgPath: (this.specificImgSrc === 'pixel' ? '/assets/images/pixel.png' : this.specificImgSrc) || this._imgSrcStore,
        presets,
        customSettings: {
          width, height, type, format, gravity, dpr: (!presets && density > 1) ? density : undefined
        }}
      )}${(density === 1 && densities.length === 1) ? '' : ' ' + density + 'x' }${(index < (densities.length - 1)) ? ',' : '' }`;
    });
    this.imgSrc = fullPath;
  }
}
