import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, Inject, Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { KzPopupService } from '../popup/kz-popup.service';
import { KzModal } from './kz-modal';
import { ModalOptions } from './modal-options';
import { KzModalPopupComponent } from './kz-modal-popup.component';

@Injectable()
export class KzModalService {
  constructor(
    private _kzPopupService: KzPopupService,
    @Inject(DOCUMENT) private _document: Document,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _applicationRef: ApplicationRef,
  ) {
  }

  create<T>(content: string | TemplateRef<any> | Type<any>, options?: ModalOptions): KzModal<T> {
    const finalOptions: ModalOptions = Object.assign(
      {
        size: 'xxl',
        backdrop: true,
        closeButton: true,
        contentBackground: true,
        container: this._document.body,
      },
      options || {},
    );
    const popup = this._kzPopupService.create(KzModalPopupComponent, finalOptions.container);

    return new KzModal<T>(
      content,
      popup,
      finalOptions,
      this._document,
      this._injector,
      this._componentFactoryResolver,
      this._applicationRef,
    );
  }
}

// import { DOCUMENT } from '@angular/common';
// import {
//   ApplicationRef,
//   Inject,
//   Injectable,
//   TemplateRef,
//   Type,
//   ViewContainerRef
// } from '@angular/core';
// import { KzPopupService } from '../popup/kz-popup.service';
// import { KzModal } from './kz-modal';
// import { ModalOptions } from './modal-options';
// import { KzModalPopupComponent } from './kz-modal-popup.component';
//
// @Injectable()
// export class KzModalService {
//   constructor(
//     private _kzPopupService: KzPopupService,
//     @Inject(DOCUMENT) private _document: Document,
//     // // private _componentFactoryResolver: ComponentFactoryResolver,
//     // // private _injector: Injector,
//     // private _viewContainerRef: ViewContainerRef,
//     private _applicationRef: ApplicationRef,
//   ) {
//   }
//
//   create<T>(content: string | TemplateRef<any> | Type<any>, options?: ModalOptions): KzModal<T> {
//     const finalOptions: ModalOptions = Object.assign(
//       {
//         size: 'xxl',
//         backdrop: true,
//         closeButton: true,
//         contentBackground: true,
//         container: this._document.body,
//       },
//       options || {},
//     );
//     const popup = this._kzPopupService.create<KzModalPopupComponent>(KzModalPopupComponent, finalOptions.container);
//
//     return new KzModal<T>(
//       content,
//       popup,
//       finalOptions,
//       this._document,
//       // this._viewContainerRef,
//       this._applicationRef,
//     );
//   }
// }
