import { Pipe, PipeTransform } from '@angular/core';
import { UserStoreService } from "../../shared/services/state-management/user-store.service";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";

@Pipe({
  name: 'concatUserId',
  standalone: true,
})
export class ConcatUserIdPipe implements PipeTransform {

  constructor(
    private readonly userStoreService: UserStoreService,
  ) {
  }
  transform(url: string): Observable<string> {
    return this.userStoreService.userStore.pipe(
      map((user) => !!user ? `${url}${user["@id"].replace('/users', '')}` : url),
    );
  }

}
