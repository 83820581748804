import {
  ConsoleLogTransporter,
  KOZIKAZA_LOG_TRANSPORTER,
  LevelLogTransporter,
  PipelineLogTransporter,
} from '@adeo/ngx-kozikaza-api';
import { ErrorHandler, Inject, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { AppMissingTrans, AppModule } from './app.module';
import { AppComponent } from './app.component';
import { makeStateKey, Title, TransferState } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { RoutesService } from './shared/services/routes/routes.service';
import { MediaQueriesService } from './shared/services/utilities/media-queries.service';
import { isPlatformBrowser } from '@angular/common';
import { PublisherTagService } from './shared/services/utilities/publisher-tag.service';
import { GlobalErrorHandler } from './shared/error-handler/global-error-handler';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { WebSocketsService } from './shared/services/web-sockets/web-sockets.service';
import { QuillModule } from 'ngx-quill';
import QuillAutoDetectUrl from 'quill-auto-detect-url';
import { translateModuleLoader } from "./shared/services/http-loader.service";

const socketConfig: SocketIoConfig = { url: environment.API_SOCKET_ORIGIN, options: {
    path: '/chat/socket.io',
    transports: [
      'websocket'
    ],
    reconnectionAttempts: 2,
  }
};

@NgModule({
  imports: [
    AppModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: translateModuleLoader,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingTrans,
      },
    }),
    SocketIoModule.forRoot(socketConfig),
    QuillModule.forRoot({
      customModules: [{
        implementation: QuillAutoDetectUrl,
        path: 'modules/autoDetectUrl'
      }],
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: 'DEFAULT_COUNTRY',
      useFactory: (transferState: TransferState) => {
        return transferState.get(makeStateKey('DEFAULT_COUNTRY'), undefined);
      },
      deps: [TransferState]
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: KOZIKAZA_LOG_TRANSPORTER,
      useFactory: () => new PipelineLogTransporter([
        new LevelLogTransporter(environment.LOG_LEVEL),
        new ConsoleLogTransporter({format: 'text'}),
      ]),
    },
  ],
})
export class AppBrowserModule {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    @Inject(PLATFORM_ID) private platformId: object,
    private cookieService: CookieService,
    private mediaQueriesService: MediaQueriesService,
    private publisherTagService: PublisherTagService,
    private routesService: RoutesService,
    @Inject(LOCALE_ID) readonly language,
    private webSocketsService: WebSocketsService,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.webSocketsService.initService();
      /*router.events.pipe(
        filter((e) => e instanceof NavigationEnd),
        take(1),
        tap((event: NavigationEnd) => {
          this.initFirstPage();
        }),
      ).subscribe();*/
    }
  }

  initFirstPage(): void {
    /*
     This code is let here commented to remind something :
     From now, the war is started ! The war of bug, side effects... You know why ?
     The following code was migrated to GTM... Now the Data Team will execute JS scripts...

     Don't blame me but it was hard to find the perfect solution...
     The initial subject was blocking this cookies from OneTrust solution, but third services block scripts
     to block the cookies. This file is compiled and built into "main-es.xxxxxx.js" with the full of code of the app.
     If OneTrust block this file, the while app is blocked...

     R.I.P the family <3

     <<<< Seen with KZ && Morchipouette >>>>

     RDF 30/03/2021 14:38:17 - Listening "Ricii Lompeurs - Far Away" (https://www.youtube.com/watch?v=swE10dfMCVk) :)
    */

    /*const firstPageSeen = this.cookieService.get('firstPageSeen');
    if (!firstPageSeen) {
      const fpsCookieParams: CookieOptions = {...defaultCookieParams, expires: moment().add('1', 'year').toDate()};
      this.cookieService.put('firstPageSeen', window.location.pathname, fpsCookieParams);
      this.cookieService.put('firstPageSeen_template', this.routesService.extractTemplateFromRoute(), fpsCookieParams);
      this.cookieService.put('firstPageSeen_language', this.language, fpsCookieParams);
      this.cookieService.put('firstPageSeen_params', JSON.stringify(this.routesService.extractParamsFromRoute()), fpsCookieParams);
    }*/
  }
}
