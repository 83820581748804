import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgResizeModule } from 'src/app/shared/components/img-resize/img-resize.module';
import { GtmTrackModule } from 'src/app/utils/directives/gtm-track/gtm-track.module';
import { DateDetailComponent } from "../date-detail/date-detail.component";
import { ConcatUserIdPipe } from "../../../utils/pipes/concat-user-id.pipe";
import { Brand, Deal } from '@adeo/ngx-kozikaza-api/affiliate';

@Component({
  selector: 'app-good-deals-menu',
  standalone: true,
  imports: [
    CommonModule,
    ImgResizeModule,
    GtmTrackModule,
    DateDetailComponent,
    ConcatUserIdPipe,
  ],
  templateUrl: './good-deals-menu.component.html',
  styleUrls: ['./good-deals-menu.component.scss']
})
export class GoodDealsMenuComponent implements OnChanges{
  @Input() parentMenu = 'menu_good_deals';
  @Input() deal: Deal;
  brand: Brand;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.hasOwnProperty('deal')) {
      this.brand = this.deal.brand as Brand;
    }
  }
}
