import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: '[kzLabel]',
  exportAs: 'kzLabel',
  host: {
    '[class]': '"kz-label" + (classes ? " " + classes : "")',
  },
  template: `<ng-content></ng-content>`,
  styleUrls: ['./kz-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KzLabelComponent implements OnInit, OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private _isRequired: boolean;

  get isRequired(): boolean {
    return this._isRequired;
  }

  @Input()
  set isRequired(value: boolean) {
    this._isRequired = value;

    this.toggleStar();
  }

  private _starSpan: ElementRef;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private toggleStar() {
    if (this.isRequired) {
      this._starSpan = this._renderer.createElement('span');
      this._renderer.addClass(this._starSpan, 'kz-label-star');
      const text = this._renderer.createText('*');
      this._renderer.appendChild(this._starSpan, text);
      this._renderer.appendChild(this._elementRef.nativeElement, this._starSpan);
    } else if (this._starSpan) {
      this._renderer.removeChild(this._elementRef.nativeElement, this._starSpan);
    }

  }

  private _updateClasses() {
    this._classes = '';
  }
}
