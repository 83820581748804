import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input, OnChanges, OnInit,
  Optional, PLATFORM_ID, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { KzIconsRegistryService } from './kz-icons-registry.service';
import { DOCUMENT } from '@angular/common';
import { kzIcon } from './generated-icons/kz-icon.model';
import { IconSize } from './icons-options';

@Component({
  selector: 'kz-icons',
  template: `<ng-content></ng-content>`,
  host: {
    '[class]': '"kz-icons" + (classes ? " " + classes : "")',
  },
  styles: [`
    .kz-icons {
      display: inline-flex;
      svg{
        max-width: 100%; max-height: 100%;
        width: 1.5em; height: 1.5em;
        &.kz-icons-width--2em{
          width: 2em;
        }
      }
      &.kz-icons-width-auto {
        svg {
          width: auto;
        }
      }
    }

    .kz-icons-size--xs svg {
      width: 1em; height: 1em;
    }
    .kz-icons-size--sm svg {
      width: 1.25em; height: 1.25em;
    }
    .kz-icons-size--md svg {
      width: 1.75em; height: 1.75em;
    }
    .kz-icons-size--l svg {
      width: 2em; height: 2em;
    }
    .kz-icons-size--xl svg {
      width: 2.5em; height: 2.5em;
    }
    .kz-icons-inline {
      display: inline;
      top: 5px;
      position: relative;
      & + * {
        margin-right: .5em;
      }
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KzIconsComponent implements OnInit, OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private svgIcon: SVGElement;

  private _size: IconSize;

  get size(): IconSize {
    return this._size;
  }

  @Input()
  set size(value: IconSize) {
    this._size = value;
  }

  private _name: string;

  @Input()
  set name(iconName: kzIcon | string) {
    this._name = iconName.replace(/-/g, '_');
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this.kzIconRegistry.getIcon(this._name);
    this.svgIcon = this.svgElementFromString(svgData);
    this.element.nativeElement.appendChild(this.svgIcon);
  }

  @HostBinding('attr.aria-hidden') ariaHidden = true;

  @Input() @HostBinding('class.kz-icons-inline') inline = false;

  @Input() @HostBinding('class.kz-icons-width-auto') widthAuto = false;

  constructor(
    private element: ElementRef,
    private kzIconRegistry: KzIconsRegistryService,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
  }

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
  }


  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = '';

    if (this.size && this.size !== 'default') {
      this._classes += ` kz-icons-size--${ this.size }`;
    }
    if (this._name) {
      this._classes += ` kz-icons--${ this._name }`;
    }
  }
}
