export const MISC_CONST = {
    NOTIF_REFRESH_TIMER: 240000,
    POST_MAX_FILE_SIZE: 230686720,
    TOOLTIP_NB_VOTERS: 10,
    TOOLTIP_NB_LIKERS: 10,
    NB_POSTS_OFFSET: 16,
    NB_POSTS_OFFSET_MOBILE: 8,
    NB_MEDIAS_OFFSET: 20,
    NB_KAZAS_OFFSET: 12,
    DIAPORAMA_OFFSET: 10,
    TOKYWOKY_PRIVATE: '81D4047525594AB195D84E9A9FBAC88D',
    TOKYWOKY_PUBLIC: '05DFEC75E46248B4A2E2C891BBDD1A4F',
    USER_VALIDATOR_MINLENGTH: 3,
    USER_VALIDATOR_MAXLENGTH: 20,
    USER_VALIDATOR_REGEX: /^([-_\.0-9\p{L}]+)\b$/u,
    USER_PRO_YOUTUBE_LINK_VALIDATOR_REGEX:
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    PRO_SLUG_VALIDATOR_REGEX: '^[a-z0-9]+(?:-[a-z0-9]+)*$',
    COOKIE_CONTEST_NAME: 'contestAlreadySubscribe2'
};
