import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { LocalizedRouterService } from '../../../shared/services/localized-router.service';
import { Subscription } from 'rxjs';
import { PresetLogo } from '../../../shared/components/img-resize/image-resize.service';
import { KzIconsRegistryService } from '../../../kz-ui/ui/icon/kz-icons-registry.service';
import { kzIconsKozikaza } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-kozikaza.icon';
import { kzIconsSocialInstagram } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-social-instagram.icon';
import { kzIconsSocialFacebook } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-social-facebook.icon';
import { kzIconsSocialPinterest } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-social-pinterest.icon';
import { kzIconsSocialTwitter } from '../../../kz-ui/ui/icon/generated-icons/kzIcons-social-twitter.icon';
import { DateService } from 'src/app/shared/services/utilities/date.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy, OnInit {
  public section: string;
  public showLanguageSwitch = environment.SHOW_FOOTER_LANGUAGE_SWITCH;
  public languagesLabels: Array<{lang: string, label: string}> = environment.ALLOWED_LANGUAGES_LABELS;
  private languageSwitcherSubscription: Subscription;
  year: number;

  public presetLogo = new PresetLogo();

  constructor(
    @Inject(LOCALE_ID) public currentLanguage,
    private readonly loclizedRouterService: LocalizedRouterService,
    private kzIconsRegistryService: KzIconsRegistryService,
    private dateService: DateService
  ) {
    this.kzIconsRegistryService.registerIcons([kzIconsKozikaza, kzIconsSocialInstagram, kzIconsSocialFacebook,
      kzIconsSocialPinterest, kzIconsSocialTwitter]);
  }

  ngOnInit(): void {
    this.year = this.dateService.getYear();
  }

  ngOnDestroy(): void {
    if (this.languageSwitcherSubscription && !this.languageSwitcherSubscription.closed) {
      this.languageSwitcherSubscription.unsubscribe();
    }
  }

  toggleSection(section): void {
    this.section = (this.section !== section) ? section : null;
  }

  changeLanguage(event) {
    this.languageSwitcherSubscription = this.loclizedRouterService.translateCurrentRoute(event.target.value).subscribe((path) => {
      window.location.href = window.location.origin + '/' + event.target.value + path;
    });
  }

  openCookieSettings() {
    const otButton: Element = document.getElementsByClassName('ot-floating-button__open').item(0);
    if (otButton && otButton instanceof HTMLElement) {
      otButton.click();
    }
  }

  trackByLang(index, data): string {
    return data ? data.lang : undefined;
  }
}
