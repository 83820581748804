import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router';
import { environment } from 'environments/environment';
import { KzUiGuard } from './shared/guards/kz-ui.guard';
import { HreflangStrategy } from './shared/models/hreflang-strategy';
import { HomeGuard } from './shared/guards/home.guard';
import { IsCommunityGuard } from './shared/guards/is-community.guard';
import { LazyComponent } from './modules/lazy/lazy.component';
import { defaultLanguageFunction } from './utils/const/localisation';
import { OAuthGuard } from './shared/guards/OAuthGuard';

export const authSlug = 'auth';
export const postSlug = 'post';
export const kazaplanSlug = 'kazaplan';

export const routes: Routes = [
  {
    path: 'authentification',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    outlet: 'auth'
  },
  {
    outlet: 'modal',
    path: ':size',
    loadChildren: () => import('./modules/modal-view/modal-view.module').then(m => m.ModalViewModule)
  },
  { path: '',
    component: LazyComponent,
    children: [
      { path: '',
        loadChildren: () => import('./modules/landing/homepage/homepage.module').then(m => m.HomepageModule),
        data: { skipRouteLocalization: true, hreflangStrategy: HreflangStrategy.Auto},
        canActivate: [HomeGuard],
        pathMatch: 'full'},
      {
        path: `kz-ui`, loadChildren: () => import('./kz-ui/kitchen-sink/kz-kitchen-sink.module')
          .then(m => m.KzKitchenSinkModule),
        canActivate: [KzUiGuard],
      },
      { path: '3d-home-design-software', loadChildren: () => import('./modules/landing/plan-3d/plan-3d.module').then(m => m.Plan3dModule),
        data: { hreflangStrategy: HreflangStrategy.Auto} },
      { path: 'chat', loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule), canActivate: [OAuthGuard, IsCommunityGuard]},
      { path: 'leroymerlin-isneauville', loadChildren: () =>
            import('./modules/landing/temporary-landings/temporary-landings.module').then(m => m.TemporaryLandingsModule)},
      { path: `${postSlug}`, loadChildren: () => import('./modules/post/post.module').then(m => m.PostModule)},
      { path: `${kazaplanSlug}`, loadChildren: () => import('./modules/kazaplan/kazaplan.module').then(m => m.KazaplanModule), data: {isKazaplanRoute: true} },
      { path: 'kaza', loadChildren: () => import('./modules/kaza/kaza.module').then(m => m.KazaModule)},
      { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)},
      { path: 'kazacafe', loadChildren: () =>
            import('./modules/kazacafe/kazacafe.module').then(m => m.KazacafeModule), canActivate: [IsCommunityGuard],
        data: { hreflangStrategy: HreflangStrategy.Auto} },
      { path: 'inspiration', loadChildren: () =>
            import('./modules/inspiration/inspiration.module').then(m => m.InspirationModule) , canActivate: [IsCommunityGuard]},
      { path: 'pro', loadChildren: () => import('./modules/pro/pro.module').then(m => m.ProModule), canActivate: [IsCommunityGuard]},
      { path: 'decoration-consultant', loadChildren: () => import('./modules/landing/pro-coach/pro-coach.module').then(m => m.ProCoachModule)},
      { path: 'decoration-coaching-box', loadChildren: () => import('./modules/landing/box-coaching-deco/box-coaching-deco.module').then(m => m.BoxCoachingDecoModule)},
      { path: 'construction-estimate', loadChildren: () => import('./modules/landing/construction-estimate/construction-estimate.module')
            .then(m => m.ConstructionEstimateModule), canActivate: [IsCommunityGuard]},
      { path: 'contest', loadChildren:  () =>
            import('./modules/contest/contest.module').then(m => m.ContestModule), canActivate: [IsCommunityGuard]},
      { path: 'activation-box', loadChildren:  () =>
            import('./modules/pro/box-activation/box-activation.module').then(m => m.BoxActivationModule)},
      { path: 'home-garden-deals', loadChildren:  () =>
            import('./modules/good-deals/good-deals.module').then(m => m.GoodDealsModule), canActivate: [IsCommunityGuard]},
      { path: 'not_found', loadChildren: () =>
            import('./modules/features/error/error.module').then(m => m.ErrorModule), data: {isFullHeader: false, status: 404}},
      { path: 'unauthorized', loadChildren: () =>
            import('./modules/features/error/error.module').then(m => m.ErrorModule), data: {isFullHeader: false, status: 403}},
      { path: 'error', loadChildren: () =>
            import('./modules/features/error/error.module').then(m => m.ErrorModule), data: {isFullHeader: false, status: 500}},
      { path: '', loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule),
        data: { hreflangStrategy: HreflangStrategy.Auto} },
      { path: '**', data: {isFullHeader: false, fromRouter: true}, redirectTo: '/not_found'}
    ]
  },
  { path: '**', data: {isFullHeader: false, fromRouter: true}, redirectTo: '/not_found' }
];

export function localizeLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings) {
  return new ManualParserLoader(translate, location, settings, environment.ALLOWED_LANGUAGES, 'routes.');
}

@NgModule({
  imports: [
    TranslateModule.forChild(),
    RouterModule.forRoot(routes,
        {
    // https://github.com/gilsdav/ngx-translate-router#deal-with-initialnavigation
    // Disabled for localize-router (to avoid the flickering of the lazy-load.)
    // initialNavigation: 'enabled',
    initialNavigation: 'disabled',
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'disabled',
    scrollOffset: [0, 36]
}),
    LocalizeRouterModule.forRoot(routes, {
      alwaysSetPrefix: false,
      useCachedLang: false,
      // Disabled for localize-router (to avoid the flickering of the lazy-load.)
      initialNavigation: true,
      defaultLangFunction: defaultLanguageFunction,
      parser: {
        provide: LocalizeParser,
        useFactory: localizeLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings]
      }
    }),
  ],
  exports: [
    RouterModule,
    LocalizeRouterModule,
  ]
})
export class AppRoutingModule { }
