import {
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, NavigationExtras, Router, Scroll, UrlTree } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { MediaQueriesService } from './shared/services/utilities/media-queries.service';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './shared/services/features/oauth/auth.service';
import { SeoService } from './shared/services/utilities/seo.service';
import { isPlatformBrowser } from '@angular/common';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { DataLayerService } from './shared/services/utilities/data-layer.service';
import { UserStoreService } from './shared/services/state-management/user-store.service';
import { filterUndefined, TokenStorage } from '@adeo/ngx-kozikaza-api';
import { RoutesService } from './shared/services/routes/routes.service';
import { InjectDirective } from './utils/directives/inject/inject.directive';
import { DateService } from './shared/services/utilities/date.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['../styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {

  @ViewChild(InjectDirective, {static: true}) injectComp: InjectDirective;

  loaded = false;

  detectIE$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isEmailVerifiedBanner$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

  public toggle = false;

  private _previousPrimaryPath: string;

  @ViewChild('debugVcr', { read: ViewContainerRef, static: true}) debugVcr: ViewContainerRef;

  constructor(
    private authService: AuthService,
    private seoService: SeoService,
    private router: Router,
    public routesService: RoutesService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(LOCALE_ID) private readonly language: string,
    @Inject('DEFAULT_COUNTRY') private readonly country: string,
    private mediaQueriesService: MediaQueriesService,
    private readonly dataLayerService: DataLayerService,
    private readonly localizeRouterService: LocalizeRouterService,
    public readonly userStoreService: UserStoreService,
    public readonly dateService: DateService,
    private tokenStorage: TokenStorage,
  ) {
    this.seoService.boot();
    this.seoService.updateLang(this.language);

    if (isPlatformBrowser(this.platformId)) {
      if (this.authService.getUserId() !== null && (this.tokenStorage.token && this.tokenStorage.token.refreshToken === null)) {
        // todo: [MATHIEU] utilité? autre méthode?
        this.authService.logout();
      }
    }
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.dataLayerService.startLiveUpdateOfAppDataLayer();
      if (!!this.cookieService.get('kzkzdebug')) {
        this.loadDebugComponent();
      }
    }

    let forceNoFollow = false;
    this.routesService.eventNavigationEnd.pipe(
      tap(() => this._handleScrollToTop()),
      // force noindex, nofollow on auxiliary router outlets routes
      tap((ev: NavigationEnd) => forceNoFollow = ((ev.url && ev.url.indexOf('auth:') !== -1) || (this.language === 'pl') || (this.language === 'es'))),
      map(() => this.route),
      map((route: ActivatedRoute) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
      switchMap((route: ActivatedRoute) => route.data),

      distinctUntilChanged((prev: Data, curr: Data) => prev.metas === curr.metas),
    ).subscribe((event) => {
      if (event.metas) {
        this.seoService.setMetas((event.metasOverride) ? event.metasOverride : event.metas);
      }
      // route defined meta robots or default meta robots
      let metasFollow = 'index, follow';
      if (event.forceChildrenFollow) {
        metasFollow = event.forceChildrenFollow;
      } else if (event.metas && event.metas.follow) {
        if (typeof event.metas.follow === 'string') {
          metasFollow = event.metas.follow;
        } else {
          if (event.metas.follow[this.language]) {
            metasFollow = event.metas.follow[this.language];
          } else if (event.metas.follow.others) {
            metasFollow = event.metas.follow.others;
          }
        }
      }
      this.seoService.initFollow(forceNoFollow ? 'noindex, nofollow' : metasFollow);
    });

    // this.cookieService.put('persisted_language', this.language, {...defaultCookieParams, expires: this.dateService.addYears(1)});
    this.detectIE$.next(this.mediaQueriesService.detectIE());

    if (['e2e', 'local'].includes(environment.env) && isPlatformBrowser(this.platformId)) {
      this.routesService.events.subscribe(
        (event) => {
          if (event instanceof NavigationEnd || event instanceof Scroll) {
            document.body.classList.add('cy-navigation-ready');
          } else {
            document.body.classList.remove('cy-navigation-ready');
          }
        },
      );
    }
  }

  public get emailVerifiedBannerIsReady(): Observable<boolean> {
    return this.isEmailVerifiedBanner$.asObservable().pipe(filterUndefined());
  }

  private _handleScrollToTop() {
    if (isPlatformBrowser(this.platformId) && !this.route.snapshot.fragment) {
      const urlTree = this.router.parseUrl(this.router.url);
      if (urlTree) {
        const currentPrimaryPath = this._extractPrimaryPathFromUrlTree(urlTree);
        if (currentPrimaryPath !== this._previousPrimaryPath) {
          this._previousPrimaryPath = currentPrimaryPath;
          window.scroll({top: 0});
        }
      }
    }
  }

  private _extractPrimaryPathFromUrlTree(urlTree: UrlTree): string {
    if (urlTree.root.children.hasOwnProperty('primary')) {
      return urlTree.root.children['primary'].segments.map((segment) => segment.path).join('/');
    }

    return '/';
  }

  loadDebugComponent() {
    import('./shared/components/debug/debug.component')
      .then(debugComponent => {
        this.debugVcr.clear();
        this.debugVcr.createComponent(debugComponent.DebugComponent);
        this.loaded = true;
      });
  }

  redirectToCGU() {
    // Set our navigation extras object
    // that contains our global query params and fragment
    const navigationExtras: NavigationExtras = {
      fragment: 'cookies',
    };
    // Navigate to the login page with extras
    this.router.navigate(this.localizeRouterService.translateRoute(['terms-of-service']) as string[], navigationExtras);
  }
}
