<kz-modal-header *ngIf="title">
  <h1 class="kz-section-title kz-section-title--{{type}}"><span [innerHTML]="title"></span></h1>
</kz-modal-header>
<kz-modal-content *ngIf="content">
  <kz-card>
    <span *ngIf="contentType != 'template'" [innerHTML]="contentString"></span>
    <ng-container  *ngIf="contentType == 'template'" >
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </ng-container>
    <div class="confirmation-modal-fullscreen--actions">
      <button *ngIf="yesAction" type="button" kzButton (click)="yes()" [buttonStyle]="'flat'" [buttonContext]="type">
        <span *ngIf="!yesActionLabel" i18n="@@popin.yesNoDialog.action.yes">Yes</span>
        <span *ngIf="yesActionLabel">{{yesActionLabel}}</span>
      </button>

      <button *ngIf="cancelAction" type="button" kzButton (click)="cancel()" [buttonStyle]="'borderless'"
              [buttonContext]="type">
        <span *ngIf="!cancelActionLabel" i18n="@@popin.yesNoDialog.action.cancel">Cancel</span>
        <span *ngIf="cancelActionLabel">{{cancelActionLabel}}</span>
      </button>

      <button *ngIf="noAction" type="button" kzButton (click)="no()" [buttonContext]="type">
        <span *ngIf="!noActionLabel" i18n="@@popin.yesNoDialog.action.no">No</span>
        <span *ngIf="noActionLabel">{{noActionLabel}}</span>
      </button>
    </div>

  </kz-card>
</kz-modal-content>
