import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ExternalScriptService } from './external-script.service';
import { BehaviorSubject, NEVER, Observable, Subject } from 'rxjs';
import { GOOGLE_ADS } from '../../../utils/const/google-ads';
import { UserStoreService } from '../state-management/user-store.service';
import { Kaza } from '@adeo/ngx-kozikaza-api';

@Injectable({
  providedIn: 'root',
})
export class PublisherTagService {

  private slotStore: Array<{ id: string, slot: any }> = [];
  private gptInterstitielInitialized = false;
  private firstCall: boolean;
  public scriptExist$: BehaviorSubject<any> = new BehaviorSubject(false);
  private _initScript$: Subject<void> = new Subject();

  constructor(
    @Inject(DOCUMENT) private doc: any,
    @Inject(PLATFORM_ID) private platformId: object,
    private externalScriptService: ExternalScriptService,
    private userStoreService: UserStoreService,
  ) {
    // if (isPlatformBrowser(this.platformId)) {
    //   this._initScript$.asObservable().pipe(
    //     switchMap(() => {
    //       this.scriptExist$.next(!!this.doc.getElementById(GOOGLE_ADS.SCRIPTS.googletagservices.url));
    //       if (!this.scriptExist$.getValue()) {
    //         return forkJoin([this.initInterstitielScript(), this.initGoogleAdScript()]).pipe(
    //           tap(() => this.scriptExist$.next(true)),
    //         );
    //       }
    //     }),
    //   ).subscribe();
    // }
  }

  initGPT(): boolean {
    if (isPlatformBrowser(this.platformId) && !this.scriptExist$.getValue()) {
      this._initScript$.next();
      return true;
    } else {
      return false;
    }
  }

  private initGoogleAdScript(): Observable<any> {
    return this.externalScriptService.loadScript(GOOGLE_ADS.SCRIPTS.googletagservices.url,
      true, false, GOOGLE_ADS.SCRIPTS.googletagservices.id);
  }

  private initInterstitielScript(): Observable<any> {
    return this.externalScriptService.loadScript(GOOGLE_ADS.SCRIPTS.securepubads.url,
      true, false, GOOGLE_ADS.SCRIPTS.securepubads.id, 'head');
  }

  public removeInterstitiel() {
    const div = this.doc.getElementById(GOOGLE_ADS.INTERSTITIEL.optDiv);

    if (div) {
      div.remove();
    }
  }

  public displayInterstitiel() {
    /*if (isPlatformBrowser(this.platformId)) {
      this.scriptExist$.asObservable().pipe(
        filter((a) => !!a),
        take(1),
        switchMap(() => {
          const div = this.doc.getElementById(GOOGLE_ADS.INTERSTITIEL.optDiv);

          if (!div) {
            const head = this.doc.getElementsByTagName('body')[0];
            const s1 = this.doc.createElement('div');
            s1.id = GOOGLE_ADS.INTERSTITIEL.optDiv;
            head.prepend(s1);
          }

          const googletag: any = window[`googletag`] || {};

          if (window[`googletag`] && googletag.apiReady) {
            googletag.cmd = googletag.cmd || [];
            googletag.cmd.push(() => {
              googletag.defineOutOfPageSlot(GOOGLE_ADS.INTERSTITIEL.adUnitPath, GOOGLE_ADS.INTERSTITIEL.optDiv).addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
            });
          }


          // const googletag: any = window[`googletag`] || {};
          if (googletag && googletag.apiReady) {
            const activeKaza = this.userStoreService.userActiveKaza;
            const types = (activeKaza && activeKaza.types)
              ? activeKaza.types.map(t => typeof t !== 'string' ? t.type : t) : 'non-connecte';
            const tags = (activeKaza && activeKaza.tags) ? activeKaza.tags.map(
              t => typeof t !== 'string' ? this.slugify(t.name) : t) : 'non-connecte';
            googletag.cmd.push(() => {
              googletag.pubads().setTargeting('chantier', types).setTargeting('projet', tags);
            });
            googletag.display(GOOGLE_ADS.INTERSTITIEL.optDiv);
            return of(true);
          } else {
            return throwError('notReady');
          }
        }),
        retryWhen((errors) =>
          errors.pipe(
            tap(val => console.log(`Error msg:  ${ val } `)),
            delayWhen(val => timer(1000)),
          ),
        ),
      ).subscribe();
    }*/
  }

  public addGoogleAds(ads: Array<{ adUnitPath: string, size: any, optDiv: string }>): Observable<any> {
    // this.firstCall = true;
    // if (isPlatformBrowser(this.platformId)) {
    //   return this.userStoreService.userStoreActiveKaza.pipe(
    //     filter((kaza) => {
    //       if ((!this.firstCall && kaza === null) || (kaza === undefined)) {
    //         return false;
    //       } else {
    //         return true;
    //       }
    //     }),
    //     distinctUntilChanged((prev, curr) => (!prev && !curr) || (!!prev && !!curr && prev['@id'] === curr['@id'])),
    //     switchMap((activeKaza) => {
    //       return this.initGoogleAdScript().pipe(
    //         map(() => activeKaza),
    //       );
    //     }),
    //     tap((activeKaza) => {
    //       this.firstCall = false;
    //       const googletag: any = window[`googletag`] || {cmd: []};
    //       if (window[`googletag`] && googletag.apiReady) {
    //         googletag.cmd = googletag.cmd || [];
    //         ads.forEach((ad, index) => {
    //           const slotAlreadyExists = this.slotStore.find((store) => store.id === ad.optDiv);
    //           if (slotAlreadyExists) {
    //             googletag.cmd.push(() => {
    //               googletag.destroySlots([slotAlreadyExists.slot]);
    //               this.slotStore = this.slotStore.filter((store) => store.id !== ad.optDiv);
    //             });
    //           }
    //           googletag.cmd.push(
    //             () => {
    //               this.createSlot(ad.adUnitPath, ad.size, ad.optDiv, activeKaza);
    //               googletag.pubads().enableSingleRequest();
    //               googletag.pubads().collapseEmptyDivs();
    //               // googletag.pubads().disableInitialLoad();
    //               googletag.enableServices();
    //             },
    //           );
    //         });
    //       }
    //     }),
    //     delay(1000),
    //     tap(() => {
    //       const googletag: any = window[`googletag`] || {cmd: []};
    //       ads.forEach((ad, index) => {
    //         googletag.cmd.push(
    //           () => {
    //             googletag.display(ad.optDiv);
    //           },
    //         );
    //       });
    //     }),
    //   );
    // } else {
    //   return NEVER;
    // }

    return NEVER;
  }

  private createSlot(adUnitPath: string, size: any, optDiv: string, activeKaza: Kaza) {
    const googletag: any = window[`googletag`] || {cmd: []};
    if (window[`googletag`] && googletag.apiReady) {
      let slot;
      const types = (activeKaza && activeKaza.types) ? activeKaza.types.map(t => typeof t !== 'string' ? t.type : t) : 'non-connecte';
      const tags = (activeKaza && activeKaza.tags) ? activeKaza.tags.map(
        t => typeof t !== 'string' ? this.slugify(t.name) : t) : 'non-connecte';
      slot = googletag.defineSlot(adUnitPath, size,
        optDiv).setTargeting('chantier', types).setTargeting('projet', tags).addService(googletag.pubads());
      this.slotStore.push({id: optDiv, slot});
      return slot;
    }
    return null;
  }

  private slugify(input: string): string {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return input.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

}
