import { Logger, LogLevel } from '@adeo/ngx-kozikaza-api';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private logger: Logger,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
  }

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message) && isPlatformBrowser(this.platformId)) {
      this.logger
        .level(LogLevel.ERROR)
        .group('GlobalErrorHandler')
        .message('Chunk loading error')
        .data({error})
        .log();

      const r = confirm($localize`:@@toast.error_reload:Following a site update and bug fixes, a new version is available. To access it, click \"OK\".`);
      if (r) {
        window.location.reload();
      }
    }

    Sentry.captureException(error.originalError || error);

    if (isPlatformBrowser(this.platformId)) {
      console.log(error);
    } else {
      this.logger
        .level(LogLevel.ERROR)
        .group('GlobalErrorHandler')
        .message('Sentry error')
        .data({error})
        .log();
    }
  }
}
