import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import {
  UserService,
  UserSuggestions,
} from '@adeo/ngx-kozikaza-api';
import { KazaLinkPipe } from '../../../../utils/pipes/link/kaza-link.pipe';
import { KzIconsRegistryService } from '../../../../kz-ui/ui/icon/kz-icons-registry.service';
import { kzIconsSearch } from '../../../../kz-ui/ui/icon/generated-icons/kzIcons-search.icon';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchForm: UntypedFormGroup;
  keyword: string = null;
  hasSuggestion = false;
  suggestions: Array<UserSuggestions> = [];
  getUserSuggestion: Subscription;
  displaySuggestion = false;
  submitted = false;
  @ViewChild('inputField', {read: ElementRef, static: false}) inputField: ElementRef;

  @HostBinding('class.kz-header-navbar-item--search-content') hostClass = true;

  @Output() public closed = new EventEmitter();
  private _opened = false;
  @Input()
  set opened(value: boolean) {
    this._opened = value;
    if (value) {
      this.inputField.nativeElement.focus();
    }
  }

  constructor(
      private router: Router,
      private userService: UserService,
      private route: ActivatedRoute,
      private fb: UntypedFormBuilder,
      private kazaLinkPipe: KazaLinkPipe,
      private readonly localizeRouterService: LocalizeRouterService,
      private readonly cdr: ChangeDetectorRef,
      private kzIconsRegistryService: KzIconsRegistryService,
  ) {
    this.kzIconsRegistryService.registerIcons([kzIconsSearch]);
  }

  ngOnInit() {
    this.keyword = this.route.snapshot.queryParams.q || '';
    this.searchForm  = this.fb.group({
      keywordSuggest: [
        this.keyword , []
      ]
    });
    this.hasSuggestion = false;
    this.displaySuggestion = false;
    this.loadSuggestion();
  }

  close(kazaId: string) {
    const path = this.localizeRouterService.translateRoute(this.kazaLinkPipe.transform(kazaId, 'story')) as string[];
    this.searchForm.get('keywordSuggest').setValue('');
    this.closed.emit(true);
    this.router.navigate(path, {  queryParams: { noredirect: true } });
  }

  search() {
    this.hasSuggestion = false;
    this.displaySuggestion = false;
    this.suggestions = [];

    const searchedValue = this.searchForm.controls.keywordSuggest.value || '';
    this.searchForm.get('keywordSuggest').setValue('');
    this.closed.emit(true);
    this.router.navigate(this.localizeRouterService.translateRoute(['/kazacafe', 'explore']) as string[],
        {  queryParams: { q: searchedValue } });
  }

  loadSuggestion() {
    this.searchForm.controls.keywordSuggest.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      if (data.length > 2) {
        if (this.submitted) {
          this.getUserSuggestion.unsubscribe();
        }
        this.submitted = true;
        this.cdr.markForCheck();
        this.getUserSuggestion = this.userService.suggestUsers(data).subscribe((suggestedUsers) => {
          this.submitted = false;
          this.hasSuggestion = !!suggestedUsers;
          this.displaySuggestion = !!suggestedUsers;
          this.suggestions = suggestedUsers || [];
          this.cdr.markForCheck();
        });
      } else {
        this.hasSuggestion = false;
        this.displaySuggestion = false;
        this.suggestions = [];
        this.cdr.markForCheck();
      }
    });
  }

  trackById(index, data): number {
    return data ? data.id : undefined;
  }
}
