import { Inject, Injectable, Injector, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, iif, merge, Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import { catchError, debounceTime, delay, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { UserStoreService } from '../state-management/user-store.service';
import {
  distinctHydraMember,
  filterUndefined,
  TokenStorage,
  UserService,
} from '@adeo/ngx-kozikaza-api';

@Injectable({
  providedIn: 'root'
})
export class WebSocketsService implements OnDestroy {

  private _socketSubscription: Subscription;
  private askRefreshUser = false;
  private socket: Socket | null = null;
  private _socketIsConnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public socketIsConnected$: Observable<boolean> = this._socketIsConnected.asObservable();

  constructor(
    private readonly injector: Injector,
    private readonly userStoreService: UserStoreService,
    private readonly userService: UserService,
    private readonly tokenStorage: TokenStorage,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
  }

  initService() {
    if (isPlatformBrowser(this.platformId)) {
      this.socket = this.injector.get<Socket>(Socket);
      this._socketSubscription = merge(
        this.listenToSocketAuthenticate(),
        this.listenToSocketDisconnect(),
        this.listenToChatUnreadConversations(),
      ).subscribe();
    }
  }

  /*
  * Listener
  * */
  listenToSocketAuthenticate() {
    let first = true;
    return this.socket.fromEvent('authentication').pipe(
      tap((authentication) => console.log('%cSOCKET > authentication', 'color:pink', authentication)),
      tap(() => this._socketIsConnected.next(true)),
      switchMap(() => this.userStoreService.userStore.pipe(
          filterUndefined(),
          filter(() => this.isAuhorized),
          // filter(() => (!!this.tokenStorage?.token?.idTokenPayload?.customFields?.beta_test_kozikaza)),
          tap((user) => console.log('%cSOCKET > $$$ > B4 > user', 'color:green', user)),
          switchMap((user) => {
            return iif(
              () => this.askRefreshUser,
              of(false).pipe(tap(() => this.askRefreshUser = false)),
              of(user),
            )
          }),
          tap((user) => console.log('%cSOCKET > $$$ > user', 'color:green', user)),
          distinctHydraMember(),
          tap((user) => console.log('%cSOCKET > authentication > user', 'color:blue', user)),
          map((user) => !!user),
          tap((islogged) => console.log('%cSOCKET > authentication > islogged', 'color:blue', islogged)),
          tap((islogged) => {
            if (islogged) {
              console.log('%cSOCKET > authentication > islogged > socketAuthenticate', 'color:blue', islogged, this.socket.ioSocket);
              this.socketAuthenticate();
            } else if (!first){
              if (!!this.socket.ioSocket.connected) {
                console.log('%cSOCKET > authentication > is NOT logged > DISCONNECT', 'color:red', islogged, this.socket.ioSocket);
                this.socket.disconnect();
              }
            }
            first = false;
          })
        )
      ),
    );
  }

  socketAuthenticate() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.socket.ioSocket.connected) {
        console.log('%cSOCKET > socketAuthenticate >  CONNECT', 'color:red', this.socket.ioSocket);
        try {
          this.socket.connect();
        } catch(err) {
          console.error('KKKKKKKK', err);
        }

      } else {
        console.log('%cSOCKET > socketAuthenticate >  authenticate', 'color:red', this.socket.ioSocket);
        this.socket.emit('authenticate', this.tokenStorage.token.accessToken);
      }
    }
  }

  checkSocketConnected() {
    console.log('%cWEB SOCKET SERVICE > checkSocketConnected', 'color: blue', this.socket.ioSocket.connected);
    if (isPlatformBrowser(this.platformId)) {
      if (!this.socket.ioSocket.connected) {
        console.log('%cWEB SOCKET SERVICE > socketAuthenticate >  CONNECT', 'color:blue', this.socket.ioSocket);
        this.socket.connect();
      } else {
        console.log('%cWEB SOCKET SERVICE > socketAuthenticate > ALREADY  CONNECTED', 'color:blue', this.socket.ioSocket);
        this._socketIsConnected.next(true);
      }
    }
  }

  listenToSocketDisconnect(): Observable<boolean> {
    return this.socket.fromEvent('disconnect').pipe(
      tap((disconnect) => console.log('%cSOCKET > disconnect', 'color:orange', disconnect)),
      delay(2000),
      withLatestFrom(this.userStoreService.isLoggedInStore),
      switchMap(([disconnect, islogged]) => {
        if (!islogged) {
          console.log('%cSOCKET > disconnect > NOT LOGGED', 'color:orange', disconnect, islogged);
          return of(null);
        }
        if (!this.isAuhorized) {
          console.log('%cSOCKET > disconnect > NOT AUTHORIZED', 'color:orange', disconnect, islogged);
          return of(null);
        }
        console.log('%c listenToSocketDisconnect', 'color:pink');
        this._socketIsConnected.next(false);
        if (!!this.socket.ioSocket.connected) {
          // in case focus lost during long time, socket could have been reconnected by SocketIoConfig.
          console.log('%c listenToSocketDisconnect > Socket is CONNECTED', 'color:pink');
          this._socketIsConnected.next(true);
        }
       if (islogged && (!this.tokenStorage.token.expiresAt || (this.tokenStorage.token.expiresAt && this.tokenStorage.token.expiresAt.getTime() > (new Date()).getTime()))) {
          console.log('%cSOCKET > disconnect > LOGGED', 'color:orange', this.tokenStorage.token);
          this.socketAuthenticate();
          return of(true);
        } else {
          console.log('%cSOCKET > disconnect > ELSE', 'color:orange', disconnect, islogged);
          this.askRefreshUser = true;
          return this.userService.me(null, {refresh: true}).pipe(
            catchError((err) => {
              console.log('%cSOCKET > disconnect > CATCH ERROR', 'color:orange', err);
              return throwError(err);
            }),
            tap((me) => {
              this.socketAuthenticate();
              console.log('%cSOCKET > disconnect > me', 'color:blue', me)
            }),
            map(() => true)
          );
        }
        console.log('%cSOCKET > disconnect > END', 'color:orange', disconnect, islogged);
        return of(false);
      }),
    );
  }

  listenToChatUnreadConversations(): Observable<number> {
    return this.socket.fromEvent<number>('unread_conversations').pipe(
      debounceTime(1500),
      // tap((nb: number) => console.log('%cSOCKET > listenToChatUnreadConversations', 'color:blue', nb)),
      tap((nb: number) => this.userStoreService.userNbMessages = nb),
    )
  }

  get isAuhorized() {
    // return (!!this.tokenStorage?.token?.idTokenPayload?.customFields?.beta_test_kozikaza);
    return true;
  }

  ngOnDestroy() {
    // console.log('%cSOCKET > KILL', 'color: red');
    this.killService();
  }

  killService() {
    console.log('%c killService > _socketIsConnected > FALSE', 'color:pink');
    this._socketIsConnected.next(false);
    if (this._socketSubscription && !this._socketSubscription.closed) {
      this._socketSubscription.unsubscribe();
    }
  }
}
