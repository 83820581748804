<footer class="kz-footer kz-landing">
  <div class="kz-container">
    <div class="kz-container--item-75percent">
      <div class="kz-footer--links-container">
        <div class="kz-footer--links" [ngClass]="{'links-open': section == 'about'}">
          <strong (click)="toggleSection('about')" i18n="@@footer.about">About Kozikaza</strong>
          <ul>
            <li><a [routerLink]="['/about'] | localize" i18n="@@footer.who-are-we">About us</a></li>
            <li><a [href]="'https://jobs.adeoservices.com/jobs?query=kozikaza'" target="_blank" i18n="@@footer.kzkz-recruits">Kozikaza is recruiting</a></li>
            <li><a [routerLink]="['/terms-of-service'] | localize" i18n="@@footer.cgu">Terms of Use</a></li>
            <li><a [routerLink]="['/terms-of-sales'] | localize" i18n="@@footer.cgv">Terms of Sale</a></li>
            <li><a [routerLink]="['/legal-notice'] | localize" i18n="@@footer.legal-notice">Legal notice</a></li>
            <li><button id="ot-sdk-btn" class="ot-sdk-show-settings" i18n="@@footer.cookies-settings">Cookies settings</button></li>
          </ul>
        </div>

        <div class="kz-footer--links" [ngClass]="{'links-open': section == 'help'}">
          <strong (click)="toggleSection('help')" i18n="@@footer.plan-3d">3D plan</strong>
          <ul>
            <li><a [routerLink]="['/3d-home-design-software'] | localize" i18n="@@footer.make-plan">Make a plan</a></li>
            <li><a href="https://kazaplan.zendesk.com/hc/fr" rel="noreferrer" target="_blank" i18n="@@footer.questions">Help centre</a></li>
            <li *ngIf="currentLanguage == 'fr'"><a href="https://kazaplan.zendesk.com/hc/fr/categories/115000464953-LES-BASES-DU-PLAN-3D" rel="noreferrer" target="_blank" i18n="@@footer.video-3d">Video tutorials</a></li>
          </ul>
        </div>

        <div *appNgIfIsCommunity class="kz-footer--links" [ngClass]="{'links-open': section == 'community'}">
          <strong (click)="toggleSection('community')" i18n="@@footer.community">Community</strong>
          <ul>
            <li><a [routerLink]="['/kazacafe'] | localize" i18n="@@footer.kazacafe">Kazacafé</a></li>
            <li><a href="https://kazaplan.zendesk.com/hc/fr/requests/new" rel="noreferrer" target="_blank" i18n="@@footer.contact">Contact us</a></li>
          </ul>
        </div>

        <div class="kz-footer--links" [ngClass]="{'links-open': section == 'pro'}">
          <strong (click)="toggleSection('pro')" i18n="@@footer.pro">Professional</strong>
          <ul>
            <li><a [routerLink]="['/pro'] | localize" i18n="@@footer.pro.listing">Go to pro directory</a></li>
            <li><a [routerLink]="['/pro', 'exchange-secure-payment'] | localize" i18n="@@footer.pro.landing.coachingDecoChat">Messaging, payment decoration coaching</a></li>
            <li><a [routerLink]="['/decoration-coaching-box'] | localize" i18n="@@footer.pro.landing.decorationCoachingBox">Decoration coaching box</a></li>
            <li><a [routerLink]="['/pro', 'decoration'] | localize" i18n="@@footer.pro.landing.decoration">Decoration professional</a></li>
            <li><a [routerLink]="['/activation-box'] | localize" i18n="@@footer.pro.landing.activationBox">Activate gift box</a></li>
            <li><a [routerLink]="['/pro', 'find-a-professional'] | localize" i18n="@@footer.pro.landing.findAPro">Find a professional</a></li>
            <li><a [routerLink]="['/3d-home-design-software','design-by-a-professional'] | localize" appGtmTrack="menu_plan3D_3DdesignService" i18n="@@footer.pro.landing.3ddesignservice">3D design service</a></li>
          </ul>
        </div>

      </div>
    </div>

    <div class="kz-container--item-25percent">
      <div class="kz-footer--social-container">
        <div class="kz-footer--logo">
          <a [routerLink]="['/'] | localize" [queryParams]="{noredirect: true}">
            <kz-icons [name]="'kozikaza'"></kz-icons>
          </a>
        </div>

        <div *appNgIfIsCommunity class="kz-footer--social kz-footer--link">
          <strong (click)="toggleSection('follow-us')" i18n="@@footer.follow-us">Follow us</strong>
          <a href="https://www.instagram.com/kozikaza" title="Instagram" target="_blank">
            <kz-icons [name]="'social_instagram'"></kz-icons>
          </a>
          <a href="https://www.facebook.com/kozikaza/" title="Facebook" target="_blank"><kz-icons [name]="'social_facebook'"></kz-icons></a>
          <a href="https://www.pinterest.fr/kozikaza/" title="Pinterest" target="_blank"><kz-icons [name]="'social_pinterest'"></kz-icons></a>
          <a href="https://twitter.com/kozikaza" title="Twitter" target="_blank"><kz-icons [name]="'social_twitter'"></kz-icons></a>
        </div>

        <kz-field *ngIf="showLanguageSwitch && languagesLabels" class="language-select">
          <select kzSelect (change)="changeLanguage($event)">
            <option *ngFor="let language of languagesLabels; trackBy: trackByLang;" [selected]="currentLanguage == language.lang" [value]="language.lang" [innerHTML]="language.label"></option>
          </select>
        </kz-field>
      </div>
    </div>
  </div>
  <div class="kz-container">
    <div class="kz-footer--sub-footer">
      <div class="kz-container--item-75percent">
        <span class="kz-footer--address" i18n="@@footer.address">135 Rue Sadi Carnot, 59790 Ronchin</span>
      </div>
      <div class="kz-container--item-25percent">
        <span class="kz-footer--copyright" i18n="@@footer.copyright">{{year}} © Kozikaza</span>
      </div>
    </div>
  </div>
</footer>
