export const GOOGLE_ADS = {
    SCRIPTS: {
        googletagservices: {
            id: 'googletagscript',
            url: 'https://www.googletagservices.com/tag/js/gpt.js',
        },
        securepubads: {
            id: 'googletaginterstitielscript',
            url: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        },
    },
    INTERSTITIEL: {
        adUnitPath: '/59994433/Test_Interstitiel',
        optDiv: 'div-gpt-ad-1582900251977-0',
    },
    ADS: {
        plan3d: {
            adUnitPath: '/59994433/KazaOngletPlan3D_Rectangle_728//468',
            size: [[468, 60], [728, 90]],
            optDiv: 'div-gpt-ad-1537457851948-0',
        },
        kazaplan: {
            new: {
                adUnitPath: '/59994433/NouveauPlan3D_BanniereEntiere_468x60',
                size: [[468, 60], [728, 90]],
                optDiv: 'div-gpt-ad-1457003166661-1',
            },
            edit: {
                adUnitPath: '/59994433/EditPlan3D_BanniereEntiere_468x60',
                size: [[468, 60], [728, 90]],
                optDiv: 'div-gpt-ad-1457003166661-0',
            },
        },
    },
};
