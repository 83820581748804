import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { ButtonContext, ButtonShape, ButtonSize, ButtonState, ButtonStyle, ButtonTxtStyle } from '../button-options';
import { AnimationType } from '../../../../animations/animations-options';

@Component({
  selector: '[kzButton]',
  exportAs: 'kzButton',
  host: {
    '[class]': '"kz-button" + (classes ? " " + classes : "")',
  },
  template: `<kz-shimmer *ngIf="!!buttonPlaceholder"></kz-shimmer><ng-content></ng-content>`,
  styleUrls: ['./kz-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KzButtonComponent implements OnInit, OnChanges {
  private _classes: string;

  get classes(): string {
    return this._classes;
  }

  private _buttonStyle: ButtonStyle = 'default';

  get buttonStyle(): ButtonStyle {
    return this._buttonStyle;
  }

  @Input()
  set buttonStyle(value: ButtonStyle) {
    this._buttonStyle = value;
  }

  private _buttonContext: ButtonContext = 'default';

  get buttonContext(): ButtonContext {
    return this._buttonContext;
  }

  @Input()
  set buttonContext(value: ButtonContext) {
    this._buttonContext = value;
  }

  private _buttonSize: ButtonSize = 'default';

  get buttonSize(): ButtonSize {
    return this._buttonSize;
  }

  @Input()
  set buttonSize(value: ButtonSize) {
    this._buttonSize = value;
  }

  private _buttonPlaceholder: AnimationType;

  get buttonPlaceholder(): AnimationType {
    return this._buttonPlaceholder;
  }

  @Input()
  set buttonPlaceholder(value: AnimationType) {
    this._buttonPlaceholder = value;
  }

  private _buttonShape: ButtonShape;

  get buttonShape(): ButtonShape {
    return this._buttonShape;
  }

  @Input()
  set buttonShape(value: ButtonShape) {
    this._buttonShape = value;
  }

  private _buttonState: ButtonState;

  get buttonState(): ButtonState {
    return this._buttonState;
  }

  @Input()
  set buttonState(value: ButtonState) {
    this._buttonState = value;
  }

  private _buttonTxtStyle: ButtonTxtStyle;

  get buttonTxtStyle(): ButtonTxtStyle {
    return this._buttonTxtStyle;
  }

  @Input()
  set buttonTxtStyle(value: ButtonTxtStyle) {
    this._buttonTxtStyle = value;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
    this._changeDetectorRef.detectChanges();
  }

  private _updateClasses() {
    this._classes = !!this.buttonPlaceholder ? `kz-${this.buttonPlaceholder} kz-placeholder` : '';

    if (this.buttonContext && this.buttonContext !== 'default') {
      this._classes += ` kz-button--${ this.buttonContext }`;
    }

    if (this.buttonStyle && this.buttonStyle !== 'default') {
      this._classes += ` kz-button--${ this.buttonStyle }`;
    }

    if (this.buttonShape && this.buttonShape !== 'default') {
      this._classes += ` kz-button-shape--${ this.buttonShape }`;
    }

    if (this.buttonSize && this.buttonSize !== 'default') {
      this._classes += ` kz-button-size--${ this.buttonSize }`;
    }

    if (this.buttonTxtStyle && this.buttonTxtStyle !== 'default') {
      this._classes += ` kz-button-txt--${ this.buttonTxtStyle }`;
    }

    if (this.buttonState && this.buttonState !== 'default') {
      this._classes += ` kz-button--${ this.buttonState }`;
    }
  }
}
