import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  distinctHydraMember,
  filterNullOrUndefined,
  filterUndefined,
  Professional,
  User,
  UserRegisterFrom
} from '@adeo/ngx-kozikaza-api';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { AuthPopinService } from './auth-popin.service';
import { UserStoreService } from '../../shared/services/state-management/user-store.service';
import { RoutesService } from '../../shared/services/routes/routes.service';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { CommunityService, CommunityState } from '../../shared/services/community.service';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'environments/environment';
import { ModalService } from "../../utils/modals/modals.service";
import { Observable } from "rxjs/internal/Observable";
import { EmailCheckComponent } from "./email-check/email-check.component";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // private tslogger: Tslogger;

  private callBackUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private authPopinService: AuthPopinService,
      private userStoreService: UserStoreService,
      private routesService: RoutesService,
      private modalService: ModalService,
      private readonly communityService: CommunityService,
      @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    combineLatest([
      this.communityService.isCommunityChanges.pipe(
          distinctUntilChanged((prev, curr) => prev.isCommunity === curr.isCommunity),
      ),
      this.userStoreService.userStore.pipe(
          debounceTime(200),
      ),
      this.userStoreService.userStoreEmailConfirmed.pipe(
        filterUndefined(),
        distinctHydraMember(),
      ),
      this.routesService.eventNavigationEnd
    ]).pipe(
        filter(([isCommunity, user, emailConfirmed]) => !!user),
        filter(() => isPlatformBrowser(this.platformId)),
        switchMap(([isCommunity, user, emailConfirmed]) => !!user.professionalId
            ? this.userStoreService.userStoreProfessional.pipe(
                filterNullOrUndefined(),
                map(pro => [isCommunity, user, pro, emailConfirmed])
            )
            : of([isCommunity, user, null, emailConfirmed])
        ),
        filter(([isCommunity, user, pro, emailConfirmed]: [CommunityState, User, Professional, boolean]) => {
          console.log('uuuu', user);
          const stop = !this.checkInfosRequired(user, pro, isCommunity.isCommunity, emailConfirmed);
          return stop;
        }),

        switchMap(() => this.routesService.callBackUrl.pipe(filterUndefined(), take(1))),
        tap((url) => {
          if (isPlatformBrowser(this.platformId)) {
            // set callBackUrlRedirected: true to prevent authPopinService redirectOnLogin
            this.route.snapshot.data = {...this.route.snapshot.data, callBackUrlRedirected : true};
            // redirect to callBackUrl
            (url.indexOf(environment.REDIRECT_URL) !== -1 || url.indexOf('/')) !== 0 ? window.location.replace(url) :
            this.router.navigateByUrl(url, {});
            this.routesService.setCallBackUrl(undefined);
          }
        })
    ).subscribe();
  }

  redirectToLoginStep(params?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'login']
      }
    }], params).catch(() => {});
  }

  redirectToSignupStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'signup']
      }
    }]).catch(() => {});
  }

  redirectToVerifyCodeStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'verify-code']
      }
    }]).catch(() => {});
  }

  redirectToEmailCheckStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'email-check']
      }
    }]).catch(() => {});
  }

  redirectToProfileStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'profil']
      }
    }]).catch(() => {});
  }

  redirectToKazaStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'kaza']
      }
    }]).catch(() => {});
  }

  redirectToConstructionDateStep(): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'construction-date']
      }
    }]).catch((e) => {console.log(e)});
  }

  redirectToTags(step: number): void {
    this.router.navigate([{
      outlets: {
        auth: ['authentification', 'tags', step.toString()]
      }
    }]).catch(() => {});
  }

  profileIsUncompleted(user: User, pro: Professional): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > profileIsUncompleted > ', 'color:orange', {...user}, user.activeKaza);
    }
    if (user) {
      return (user.registerFrom !== UserRegisterFrom.Coaching && ((user.professionalNew === true && !user.professionalId)
          || !user.username || !user.email || !user.firstName || !user.lastName));
    }
    return false;
  }

  kazaIsUncompleted(user: User): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > kazaIsUncompleted > ', 'color:orange', {...user}, user.activeKaza);
    }
    if (user.professionalNew || user.accountType === 'professional' || user.accountType === 'school') {
      return false;
    }

    if (typeof user.activeKaza !== 'string') {
      return !user.activeKaza.country || (user.activeKaza.country.toLowerCase() === 'fr' && !user.activeKaza.postalCode)
          || user.activeKaza.types.length === 0;
    }

    return false;
  }

  constructionDateIsUncompleted(user: User): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > constructionDateIsUncompleted > ', 'color:orange', {...user}, user.activeKaza);
    }
    if (user.professionalNew || user.accountType === 'professional' || user.accountType === 'school') {
      return false;
    }

    if (typeof user.activeKaza !== 'string') {
      const hasTypeDiffNoWork =  user.activeKaza.types.some((type) =>
        typeof type !== 'string' && (type.type !== 'no-work'));

      return !user.activeKaza.constructionStartedAt && !user.activeKaza.constructionDatesAlreadyAnswered && hasTypeDiffNoWork;
    }

    return false;
  }

  kazaTagStep1IsUncompleted(user: User): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > kazaTagStep1IsUncompleted > ', 'color:orange', {...user}, user.activeKaza);
    }
    if (user.professionalNew) {
      return false;
    }

    if (typeof user.activeKaza !== 'string') {
      const hasTypeRenovationOrExtension =  user.activeKaza.types.some((type) =>
          typeof type !== 'string' && (type.type === 'renovation' || type.type === 'extension'));

      if (hasTypeRenovationOrExtension) {
        const hasStep1Tags =  user.activeKaza.tags.some((tag) =>
            typeof tag !== 'string' && (tag.registrationStep === 1));
        return  !hasStep1Tags;
      }
    }

    return false;
  }

  kazaTagStep2IsUncompleted(user: User): boolean {
    if (environment.env === 'e2e') {
      console.log('%c > kazaTagStep2IsUncompleted > ', 'color:orange', {...user}, user.activeKaza);
    }
    if (user.professionalNew) {
      return false;
    }

    if (typeof user.activeKaza !== 'string') {
      const hasTypeRenovationOrExtension =  user.activeKaza.types.some((type) =>
          typeof type !== 'string' && (type.type === 'arrangement' || type.type === 'garden'
          || type.type === 'renovation' || type.type === 'extension'));

      if (hasTypeRenovationOrExtension) {
        const hasStep2Tags =  user.activeKaza.tags.some((tag) =>
            typeof tag !== 'string' && (tag.registrationStep === 2));

        return  !hasStep2Tags;
      }
    }
    return false;
  }

  get activatedRoute(): ActivatedRouteSnapshot {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot;
  }

  checkInfosRequired(user: User, pro: Professional, isCommunity: boolean, emailConfirmed: boolean): boolean {
    // const logger = this.tslogger.subLogger('checkInfosRequired');
    console.log('%c checkInfosRequired', 'color: green', user, pro, isCommunity, emailConfirmed);
    if (!emailConfirmed) {
      console.log('%c checkInfosRequired', 'color: green', emailConfirmed);
      if (!this.routesService.routeHasOutletValue('auth', 'email-check')) {
        // logger.debug({message: 'profileIsUncompleted. redirecting...'});
        console.log('%c checkInfosRequired > call redirect', 'color: green', emailConfirmed);
        this.redirectToEmailCheckStep();
      }
    } else if (this.profileIsUncompleted(user, pro)) {
      if (!this.routesService.routeHasOutletValue('auth', 'profil')) {
        // logger.debug({message: 'profileIsUncompleted. redirecting...'});
        this.redirectToProfileStep();
      }
    } else if (this.kazaIsUncompleted(user)) {
      if (!this.routesService.routeHasOutletValue('auth', 'kaza')) {
        // logger.debug({message: 'kazaIsUncompleted. redirecting...'});
        this.redirectToKazaStep();
      }
    } else if (this.constructionDateIsUncompleted(user)) {
      if (!this.routesService.routeHasOutletValue('auth', 'construction-date')) {
        // logger.debug({message: 'kazaIsUncompleted. redirecting...'});
        console.log('redirect');
        this.redirectToConstructionDateStep();
      }
    } else if (this.kazaTagStep1IsUncompleted(user)) {
      if (!this.routesService.routeHasOutletValue('auth', 'tags')) {
        // logger.debug({message: 'kazaIsStep1Uncompleted. redirecting...'});
        this.redirectToTags(1);
      }
    } else if (this.kazaTagStep2IsUncompleted(user)) {
      if (!this.routesService.routeHasOutletValue('auth', 'tags')) {
        // logger.debug({message: 'kazaIsStep2Uncompleted. redirecting...'});
        this.redirectToTags(2);
      }
    } else {
      if (this.routesService.routeHasOutlet('auth') &&
          !this.routesService.routeHasOutletValue('auth', 'forgot-password')) {
        return this.authPopinService.closePopinAndRedirect(user, isCommunity);
      }
      return false;
    }
    return true;
  }

  verifEmail(): Observable<any> {
    // return of(null);
    const modal = this.modalService.open<EmailCheckComponent>(
      EmailCheckComponent,
      {
        size: 'md',
        closeButton: false,
      },
    );
    return modal.afterClosed().pipe();
  }
}
