import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { ImgResizeModule } from '../../../shared/components/img-resize/img-resize.module';
import { KzFieldModule } from '../../../kz-ui/ui/form/field/kz-field.module';
import { KzSelectModule } from '../../../kz-ui/ui/form/select/kz-select.module';
import { KzIconsModule } from '../../../kz-ui/ui/icon/kz-icons.module';
import { UtilsModule } from '../../../utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UtilsModule,
    LocalizeRouterModule,
    ImgResizeModule,
    KzFieldModule,
    KzSelectModule,
    KzIconsModule,
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }
