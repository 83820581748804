<div class="kz-good-deal-lite">
    <a [href]="deal.trackingLinks.tinyUrl | concatUserId | async" rel="nofollow{{!!deal.sponsored ? ' sponsored' : ''}}"
       [appGtmTrack]="parentMenu + '_card' " [trackAdId]="deal.trackingSlugName" [trackPartnerId]="brand.trackingSlugName" target="_blank">
        <div class="kz-good-deal-lite-partner">
            <app-picture-source [imgSrc]="brand.logo.contentUrl"
                                bucket="kozikaza-affiliate-files"
                                [presets]="'w40' | imgPreset"
                                alt=""
                                class="kz-good-deal-lite-partner--logo"
                                width="40" height="40"
                                [imgFitHeight]="false"
                                [preloadImages]="false" [handleX2]="true"
            ></app-picture-source>
            <div class="kz-good-deal-lite-partner--label" [innerHtml]="brand.name"></div>
        </div>
        <div class="kz-good-deal-lite-offer">
            <div [innerHtml]="deal.name"></div>
            <app-date-detail [startDate]="deal.startDate" [endDate]="deal.endDate" ></app-date-detail>
        </div>
    </a>
</div>
