import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialShareModule } from './social-share/social-share.module';
import { KzTextboxDirective } from './directives/kz-textbox.directive';
import { DropOutsideDirective } from './directives/drop-outside.directive';
import { OnBgImgLoadDirective } from './directives/on-bg-img-load.directive';
import { AutocompleteDirective } from './directives/autocomplete/autocomplete.directive';
import { AutocompleteListComponent } from './directives/autocomplete/autocomplete-list/autocomplete-list.component';
import { ArrowKeyPressDirective } from './directives/arrow-key-press.directive';
import { IframeDeferDirective } from './directives/iframe-defer.directive';
import { MoodFilter } from './pipes/mood-filter.pipe';
import { ToArrayPipe } from './pipes/to-array.pipe';
import { ToHashtagPipe, ToHashtagPipeB } from './pipes/to-hashtag.pipe';
import { IndexOfPipe } from './pipes/index-of.pipe';
import { ImgWeightOrderPipe } from './pipes/img-weight-order.pipe';
import { HashtagHighlightPipe } from './pipes/hashtag-highlight.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { LinkHighlightPipe } from './pipes/link-highlight.pipe';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { PingsPipe } from './pipes/pings.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { IsCommunityDirective } from './directives/community/is-community.directive';
import { IsNotCommunityDirective } from './directives/community/is-not-community.directive';
import { LanguageDataPipe } from './pipes/language-data.pipe';
import { CountryDataPipe } from './pipes/country-data.pipe';
import { I18nDisclaimerComponent } from '../shared/components/i18n-disclaimer/i18n-disclaimer.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TimesDirective } from './directives/times.directive';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { GtmTrackModule } from './directives/gtm-track/gtm-track.module';
import { HtmlTextPipe } from './pipes/html-text.pipe';
import { MeterToKilometerPipe } from './pipes/meterToKilometer.pipe';
import { TranslationPipe } from './pipes/translation.pipe';
import { ShortDescriptionPipe } from './pipes/short-description.pipe';
import { DfnsParseIsoPipe } from './pipes/dfns-parse-iso.pipe';
import { IsLanguageDirective } from './directives/community/is-language.directive';
import { StripTagsPipe } from './pipes/strip-tags.pipe';
import { FirstLettersPipe } from './pipes/first-letters.pipe';
import { IsBetaTesterDirective } from './directives/is-beta-tester.directive';
import { GetCustomerByCoachingRequestPipe } from './pipes/pro/get-customer-by-coaching-request.pipe';
import { DateIntervalPipe } from './pipes/date-interval.pipe';
import { InViewportDirective } from './directives/in-viewport.directive';
import { UserIsProPipe } from './pipes/entity-properties/user-is-pro.pipe';
import { KazaLinkPipe } from './pipes/link/kaza-link.pipe';
import { PlanLinkPipe } from './pipes/link/plan-link.pipe';
import { ProfileLinkPipe } from './pipes/link/profile-link.pipe';
import { PostLinkPipe } from './pipes/link/post-link.pipe';
import { ProJobNamePipe } from './pipes/entity-properties/pro-job-name.pipe';
import { ProLinkPipe } from './pipes/link/pro-link.pipe';
import { DragNDropFileDirective } from './directives/drag-n-drop-file.directive';
import { GetUserOrProPropertyPipe } from './pipes/entity-properties/get-user-or-pro-property.pipe';
import { GetMediaPipe } from './pipes/entity-properties/get-media.pipe';
import { GetUserPipe } from './pipes/entity-properties/get-user.pipe';
import { GetCommentPipe } from './pipes/entity-properties/get-comment.pipe';
import { GetKazaPipe } from './pipes/entity-properties/get-kaza.pipe';
import { GetPostMoodIconPipe } from './pipes/entity-properties/get-post-mood-icon.pipe';
import { GetProPipe } from './pipes/entity-properties/get-pro.pipe';
import { GetMoodPipe } from './pipes/entity-properties/get-mood.pipe';
import { GetEventPipe } from './pipes/entity-properties/get-event.pipe';
import { GetTagPipe } from './pipes/entity-properties/get-tag.pipe';
import { GetEventEndofNamePipe } from './pipes/entity-properties/get-event-endof-name.pipe';
import { GetProfessionalStylePipe } from './pipes/entity-properties/get-professional-style.pipe';
import { GetPostLikedPipe } from './pipes/entity-properties/get-post-liked.pipe';
import { GetPostEventPipe } from './pipes/entity-properties/get-post-event.pipe';
import { ArrayReversePipe } from './pipes/array-reverse.pipe';
import { SecondsToMinutesPipe } from './pipes/seconds-to-minutes.pipe';

@NgModule({
  imports: [
    CommonModule,
    SocialShareModule,
    LocalizeRouterModule,
  ],
  declarations: [
    DropOutsideDirective,
    KzTextboxDirective,
    OnBgImgLoadDirective,
    AutocompleteDirective,
    ArrowKeyPressDirective,
    IframeDeferDirective,
    IsCommunityDirective,
    IsNotCommunityDirective,
    TimesDirective,
    AutocompleteListComponent,
    MoodFilter,
    ToArrayPipe,
    ToHashtagPipe,
    ToHashtagPipeB,
    IndexOfPipe,
    ImgWeightOrderPipe,
    HashtagHighlightPipe,
    FileSizePipe,
    SafeUrlPipe,
    LinkHighlightPipe,
    OrderByDatePipe,
    KazaLinkPipe,
    PlanLinkPipe,
    ProfileLinkPipe,
    PingsPipe,
    PostLinkPipe,
    LanguageDataPipe,
    CountryDataPipe,
    I18nDisclaimerComponent,
    SlugifyPipe,
    HtmlTextPipe,
    MeterToKilometerPipe,
    TranslationPipe,
    ProJobNamePipe,
    ProLinkPipe,
    ShortDescriptionPipe,
    DfnsParseIsoPipe,
    IsLanguageDirective,
    StripTagsPipe,
    FirstLettersPipe,
    IsBetaTesterDirective,
    GetCustomerByCoachingRequestPipe,
    DateIntervalPipe,
    InViewportDirective,
    UserIsProPipe,
    DragNDropFileDirective,
    GetUserOrProPropertyPipe,
    GetMediaPipe,
    GetUserPipe,
    GetCommentPipe,
    GetKazaPipe,
    GetPostMoodIconPipe,
    GetProPipe,
    GetMoodPipe,
    GetEventPipe,
    GetTagPipe,
    GetEventEndofNamePipe,
    GetProfessionalStylePipe,
    GetPostLikedPipe,
    GetPostEventPipe,
    ArrayReversePipe,
    SecondsToMinutesPipe,
  ],
  exports: [
    LocalizeRouterModule,
    SocialShareModule,
    LazyLoadImageModule,
    DropOutsideDirective,
    KzTextboxDirective,
    OnBgImgLoadDirective,
    IsCommunityDirective,
    IsNotCommunityDirective,
    IsLanguageDirective,
    ToArrayPipe,
    ToHashtagPipe,
    ToHashtagPipeB,
    IndexOfPipe,
    ImgWeightOrderPipe,
    HashtagHighlightPipe,
    FileSizePipe,
    MoodFilter,
    SafeUrlPipe,
    LinkHighlightPipe,
    OrderByDatePipe,
    ArrowKeyPressDirective,
    KazaLinkPipe,
    PlanLinkPipe,
    ProfileLinkPipe,
    SlugifyPipe,
    HtmlTextPipe,
    MeterToKilometerPipe,
    AutocompleteDirective,
    PingsPipe,
    PostLinkPipe,
    IframeDeferDirective,
    LanguageDataPipe,
    CountryDataPipe,
    I18nDisclaimerComponent,
    TimesDirective,
    GtmTrackModule,
    TranslationPipe,
    ProJobNamePipe,
    ProLinkPipe,
    ShortDescriptionPipe,
    DfnsParseIsoPipe,
    StripTagsPipe,
    IsBetaTesterDirective,
    FirstLettersPipe,
    GetCustomerByCoachingRequestPipe,
    DateIntervalPipe,
    InViewportDirective,
    UserIsProPipe,
    DragNDropFileDirective,
    GetUserOrProPropertyPipe,
    GetMediaPipe,
    GetUserPipe,
    GetCommentPipe,
    GetKazaPipe,
    GetPostMoodIconPipe,
    GetProPipe,
    GetMoodPipe,
    GetEventPipe,
    GetEventEndofNamePipe,
    GetTagPipe,
    GetProfessionalStylePipe,
    GetPostLikedPipe,
    GetPostEventPipe,
    ArrayReversePipe,
    SecondsToMinutesPipe,
  ],
  providers: [
    ProfileLinkPipe,
    KazaLinkPipe,
    PostLinkPipe,
    PlanLinkPipe,
    ProLinkPipe,
    GetUserOrProPropertyPipe,
    GetKazaPipe,
  ],
})
export class UtilsModule { }
