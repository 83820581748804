import { delay, delay as delayOperator, retryWhen, scan, tap } from 'rxjs/operators';
import { merge, NEVER, Observable, of, switchMap } from 'rxjs';
import { environment } from 'environments/environment';
import { MonoTypeOperatorFunction } from 'rxjs';


export const retryWithDelay = <T>(
  delay: number,
  count = 1
): MonoTypeOperatorFunction<T> => {
  console.log('>>>retryWithDelay', delay, count);
  return (input) =>
    input.pipe(
      retryWhen((errors) =>
        errors.pipe(
          scan((acc, error) => ({ count: acc.count + 1, error }), {
            count: 0,
            error: undefined as any,
          }),
          tap((current) => {
            if (current.count > count) {
              throw current.error;
            }
          }),
          delayOperator(delay)
        )
      )
    );
};

const excludedTags = [
  // 'IsCommunityGuard',
  'isCommunityChanges'
];
const tagsCustomStyle: { id: string, style: string }[] = [
  {
    id: 'Guard',
    style: `background: purple; color: #fff; padding: 3px; font-size: 9px;`
  }
];

export function debug<T>(tag: string, message?: string) {
  return (environment.env === 'prod') ? (source: Observable<T>) => {
    return source;
  } : tap({
    next(value: T) {
      if (!excludedTags.includes(tag)) {
        const hasCustomStyle = tagsCustomStyle.findIndex((tagStyle) => tag.includes(tagStyle.id));
        console.log(`%c[${tag}: Next]`, (hasCustomStyle > -1) ? tagsCustomStyle[hasCustomStyle].style :
          `background: #009688; color: #fff; padding: 3px; font-size: 9px;`, (message) ? message : '', value);
      }
    },
    error(error) {
      console.log(`%[${tag}: Error]`, `background: #E91E63; color: #fff; padding: 3px; font-size: 9px;`, error);
    },
    complete() {
      console.log(`%c[${tag}]: Complete`, `background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;`);
    }
  });
}


export const execFunctionAfterEmit = <T>(callback: () => void, delayTime = 0) => {
  return switchMap((value: T) => merge(
    of(value),
    of(true).pipe(
      delay(2000),
      tap(() => callback()),
      switchMap(() => NEVER)
    )
  ));
};
