<i *ngIf="bgBlur && isReady">
  <picture [ngClass]="{'isLoaded' : (isLoaded$ | async)}">
    <ng-container *ngIf="useLazyLoad; then lazyLoadedTemplate else defaultTemplate"></ng-container>
  </picture>
</i>
<kz-loader [type]="'logo'" *ngIf="loader && !(isLoaded$ | async)"></kz-loader>
<figure>
  <picture *ngIf="isReady" [ngStyle]="style$ | async" [ngClass]="{'isLoaded' : (isLoaded$ | async)}">
    <ng-container *ngIf="useLazyLoad; then lazyLoadedTemplate else defaultTemplate"></ng-container>
  </picture>
  <figcaption *ngIf="!!caption">{{stringCaption}}<ng-container #vc *ngIf="!stringCaption"></ng-container></figcaption>
</figure>

<ng-template #lazyLoadedTemplate>
  <source *ngFor="let media of mediaQueriesImg; trackBy: trackByDatas" [attr.lazyLoad]="media.imgSrc"
          [media]="media.mediaQuery" [type]="'image/' + (media.format || 'jpg')">
  <source *ngIf="imgWebpUrl" [attr.lazyLoad]="imgWebpUrl" type="image/webp">
  <source *ngIf="imgDensityUrl" [attr.lazyLoad]="imgDensityUrl" [type]="'image/' + (resizeFormat || imgExtension || 'jpg')">
  <img (load)="isLoaded()"
       [attr.fetchpriority]="fetchPriority || preloadImages ? 'high' : undefined"
       [lazyLoad]="imgUrl" offset="200" [useSrcset]="false" [attr.alt]="alt" [attr.height]="height > 0 ? height : null" [attr.width]="width > 0 ? width : null"/>
</ng-template>
<ng-template #defaultTemplate>
  <source *ngFor="let media of mediaQueriesImg; trackBy: trackByDatas" [srcset]="media.imgSrc"
          [media]="media.mediaQuery" [type]="'image/' + (media.format || 'jpg')">
  <source *ngIf="imgWebpUrl" [srcset]="imgWebpUrl" type="image/webp">
  <source *ngIf="imgDensityUrl" [srcset]="imgDensityUrl" [type]="'image/' + (resizeFormat || imgExtension || 'jpg')">
  <img (load)="isLoaded()" [src]="imgUrl" [attr.alt]="alt" [attr.height]="height > 0 ? height : null" [attr.width]="width > 0 ? width : null"
      [attr.fetchpriority]="fetchPriority || preloadImages ? 'high' : undefined"
      [attr.loading]="preloadImages ? 'eager' : undefined"/>
</ng-template>
