<h1 class="kz-section-title kz-section-title--{{context}}"><span [innerHTML]="title"></span></h1>

<kz-modal-content>
  <div class="confirm" *ngIf="contentType != 'template'">

    <div *ngIf="displayPicto == 'valid'" class="picto-state picto-valid">
      <kz-icons [name]="'valid_check'" [inline]="false" [widthAuto]="false"></kz-icons>
    </div>
    <div *ngIf="displayPicto == 'invalid'" class="picto-state picto-invalid">
      <kz-icons [name]="'cross'" [inline]="false" [widthAuto]="false"></kz-icons>
    </div>
    <p [innerHTML]="content"></p>
  </div>
  <ng-container *ngIf="contentType == 'template'" >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>

</kz-modal-content>
<kz-modal-footer [border]="false">
  <a (click)="close(true)" type="button" kzButton [buttonContext]="buttonContext" [buttonStyle]="'flat'" [buttonState]="'default'"  data-cy="info-modal-close"><span>
        <ng-container i18n="@@popin.infoDialog.action.close">Close</ng-container><ng-container *ngIf="closingTimer > 0"> ({{timerObs | async}}S)</ng-container></span>
  </a>
</kz-modal-footer>
