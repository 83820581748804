import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRoute,
  Params,
} from '@angular/router';
import { Subscription, combineLatest, of, forkJoin, Observable } from 'rxjs';
import { filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { LocalizedRouterService } from '../localized-router.service';
import { MediaQueriesService } from './media-queries.service';
import {
  AuthenticationService,
  convertIRItoID,
  distinctHydraMember,
  ErrorHandlerStrategy,
  filterUndefined,
  Kaza,
  UserService,
  WebServiceOptions,
  WebServiceSubResourceFetchMode,
} from '@adeo/ngx-kozikaza-api';
import { environment } from 'environments/environment';
import { SEO_CONST } from '../../../utils/const/seo';
import { isPlatformBrowser } from '@angular/common';
import { deepCopy } from '../../../utils/misc/object-utilities';
import { UserStoreService } from '../state-management/user-store.service';
import { RoutesService } from '../routes/routes.service';
import { getTranslation } from '../../../utils/const/translation';
import { DateService } from './date.service';

export interface AppDataLayer {
  environment: {
    work?: string,
    template?: string,
    channel?: string,
  };
  content: {
    title?: string,
    language_version?: string,
    params?: Params,
    // queryParams?: Params,
    offset?: number,
  };
  event?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  user?: {
    ids?: {
      kzkz_id?: string,
      kzp_id?: number,
      kzkz_pro_id?: string,
    },
    first_page_seen?: {
      template: string,
      params: string,
      language_version: string,
    },
    created_at?: string,
    kaza_created_at?: string,
    project_type?: Array<{ id: string, name: string }>,
    project_tags?: Array<{ id: string, name: string }>,
    country?: string,
    postal_code?: string,
    owner: boolean,
  };
  user_connexion?: boolean;
  navigation?: {
    [outlet: string]: {
      path?: string,
      template?: string,
      params?: Params,
      data?: any
    }
  };
}

export interface AppResetOptions {
  resetParams?: boolean;
  resetQueryParams?: boolean;
  resetProjectType?: boolean;
  resetProjectTags?: boolean;
}

export enum GAEventAction {
  CLICK = 'click',
  DISPLAY = 'display',
  SAVE = 'save',
}

@Injectable()
export class DataLayerService {
  private liveUpdateOfAppDataLayerSubscription: Subscription;

  private currentContext = 'classic';
  private currentAppDataLayer: AppDataLayer = {environment: {}, content: {}};

  private isGtmLoaded = false;
  private resetOptions: AppResetOptions = null;

  private previousPageViewHash: string = null;

  constructor(
    private readonly routesService: RoutesService,
    private route: ActivatedRoute,
    @Inject(LOCALE_ID) readonly language,
    private readonly mediaQueriesService: MediaQueriesService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private userStoreService: UserStoreService,
    @Inject(PLATFORM_ID) private platformId: object,
    private localizedRouter: LocalizedRouterService,
    private dateService: DateService,
  ) {
    this.currentAppDataLayer.environment.work = environment.env;
    this.currentAppDataLayer.content.language_version = language;
  }

  public startLiveUpdateOfAppDataLayer() {
    const webServiceOptions: WebServiceOptions = {
      subResourceFetchMode: WebServiceSubResourceFetchMode.SYNC,
      errorHandlerStrategy: ErrorHandlerStrategy.Ignore,
      refresh: false,
    };
    this.liveUpdateOfAppDataLayerSubscription = combineLatest([
      this.routesService.eventNavigationEnd,
      this.mediaQueriesService.isMobile().pipe(
        tap((isMobile) => this.currentAppDataLayer.environment.channel = isMobile ? 'mobile' : 'desktop'),
      ),
      this.userStoreService.userStore.pipe(
        filterUndefined(),
        distinctHydraMember(),
        switchMap(user => (!user) ?
          of(null).pipe(
            tap((userData) => {
              this.currentAppDataLayer.user = undefined;
              this.currentAppDataLayer.user_connexion = false;
            }),
          ) :
          this.userService.getUser(user, [{activeKaza: [{tags: ['category']}, 'types']}], webServiceOptions).pipe(
            last(),
            tap((userData) => {
              const kazaActiveKaza: Kaza = (typeof userData.activeKaza !== 'string') ? userData.activeKaza : null;
              if (kazaActiveKaza) {
                const types = kazaActiveKaza.types.map((type) =>
                  (typeof type !== 'string') ? {id: type['@id'].replace('/project_types/', ''), name: type.name} : null);

                const tags = kazaActiveKaza.tags.map((tag) =>
                  (typeof tag !== 'string') ? {id: tag['@id'].replace('/tags/', ''), name: tag.name} : null);

                const firstPageSeenPath = userData.firstPageSeen;
                const firstPageSeenTemplate = userData.firstPageSeenDataLayerTemplate;
                const firstPageSeenLanguage = userData.firstPageSeenDataLayerLanguage;
                const firstPageSeenParams = userData.firstPageSeenDataLayerParams;

                const dataLayerUser = {
                  ids: (userData['@id'] || userData.kazaplanId || userData.professionalId)
                    ? {
                      kzkz_id: userData['@id'] ? convertIRItoID(userData['@id']) : undefined,
                      kzp_id: userData.kazaplanId ? Number(userData.kazaplanId) : undefined,
                      kzkz_pro_id: userData.professionalId
                        ? convertIRItoID(typeof userData.professionalId === 'string' ? userData.professionalId : userData.professionalId['@id'])
                        : undefined,
                    }
                    : undefined,
                  first_page_seen: (firstPageSeenPath || firstPageSeenTemplate || firstPageSeenLanguage || firstPageSeenParams)
                    ? {
                      path: firstPageSeenPath,
                      template: firstPageSeenTemplate ?? undefined,
                      params: firstPageSeenParams ?? undefined,
                      language_version: firstPageSeenLanguage ?? undefined,
                    }
                    : undefined,
                  created_at: this.dateService.formatDate('yyyy-MM-dd', userData.createdAt) || null,
                  kaza_created_at: this.dateService.formatDate('yyyy-MM-dd', kazaActiveKaza.createdAt) || null,
                  project_type: types,
                  project_tags: tags,
                  country: kazaActiveKaza.country || 'missing',
                  postal_code: (kazaActiveKaza.country && kazaActiveKaza.country.toLowerCase() === 'fr') ?
                    (kazaActiveKaza.postalCode || 'missing') : undefined,
                  owner: undefined
                };
                this.currentAppDataLayer.user = dataLayerUser;
                this.currentAppDataLayer.user_connexion = true;
              }
            }),
          ),
        ),
      ),
      this.userStoreService.userStoreActiveKaza.pipe(
        filterUndefined(),
        distinctHydraMember(),
        tap((kazaActiveKaza) => {
          if (this.currentAppDataLayer.user) {
            this.currentAppDataLayer.user.country = kazaActiveKaza.country || 'missing';
            this.currentAppDataLayer.user.postal_code = (kazaActiveKaza.country && kazaActiveKaza.country.toLowerCase() === 'fr') ?
              (kazaActiveKaza.postalCode || 'missing') : undefined;
          }
        }),
      ),
    ]).pipe(
      map(() => this.route),
      /*filter((route) => {
       return !(route.children && route.children.find((r: ActivatedRoute) => r.outlet === 'auth'));
       }),*/
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      switchMap((route) => this.getNavigationDataObs()
        .pipe(
          tap((navigation: any) => this.currentAppDataLayer.navigation = navigation),
          map(() => route),
        )),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
      tap((route) => {
        this.resetOptions = {resetParams: false, resetQueryParams: false, resetProjectType: false, resetProjectTags: false};
        // If the outlet "auth" exists, force it as main
        // When the team Data finish the migration of the new variable, remove this condition
        // see KZZ-KZZ-979
        if (!this.currentAppDataLayer.navigation || !this.currentAppDataLayer.navigation.hasOwnProperty('auth')) {
          this.currentAppDataLayer.environment.template = this.currentAppDataLayer.navigation.primary.template;
          this.currentAppDataLayer.content.params = this.currentAppDataLayer.navigation.primary.params;
          this.currentAppDataLayer.content.offset = undefined;
        } else {
          this.currentAppDataLayer.environment.template = this.currentAppDataLayer.navigation.auth.template;
          this.currentAppDataLayer.content.params = this.currentAppDataLayer.navigation.auth.params;
        }


      }),
      switchMap((route) => route.data.pipe(take(1))),
      tap((event) => {
        if (!!this.currentAppDataLayer.user) {
          const isModalPost = !!this.currentAppDataLayer.navigation?.modal?.params?.postId;
          if (isModalPost) {
            this.currentAppDataLayer.user.owner = this.isOwner(this.currentAppDataLayer.navigation.modal?.data);
          } else if (!!event.dataLayerOwnerCheck && this.currentAppDataLayer.user) {
            this.currentAppDataLayer.user.owner = this.isOwner(event);
          } else if (this.currentAppDataLayer.user) {
            this.currentAppDataLayer.user.owner = undefined;
          }
        }
        this.currentContext = (event && event.gtmContext) ? event.gtmContext : 'classic';
        this.currentAppDataLayer.content.title = (event.metas && event.metas.title) ? event.metas.title :
          (event.metas && event.metas.titleKey) ? getTranslation(event.metas.titleKey) :
            SEO_CONST.META_DEFAULT_TITLE;
        this.setCurrentAppDataLayer(this.currentAppDataLayer);
        this.sendEvent({event: 'pageView'});
      }),
    ).subscribe();
  }

  private isOwner(routeData: any): boolean {
    if (!this.currentAppDataLayer.user) {
      return undefined;
    }
    const userId = `/users/${this.currentAppDataLayer.user.ids.kzkz_id}`;
    const proId = `/professionals/${this.currentAppDataLayer.user.ids.kzkz_pro_id}`;
    switch (routeData.dataLayerOwnerCheck) {
      case 'kazaMetas':
        return (routeData['kazaMetas'] && (typeof routeData['kazaMetas'] !== 'string'))
          ? routeData['kazaMetas'].authors.some((a) => a['@id'] === userId) : false;
        break;
      case 'post':
        return (routeData['post'] && (typeof routeData['post'] !== 'string')) ? (routeData['post'].author['@id'] === userId) : false;
        break;
      case 'kazaplan':
        console.log('kazaplan', routeData);
        return (routeData['kazaplan'] && (typeof routeData['kazaplan'] !== 'string')) ? (routeData['kazaplan'].userId === this.currentAppDataLayer.user.ids.kzp_id) : false;
        break; // FIXME
      case 'proMetas':
        return (routeData['proMetas'] && (typeof routeData['proMetas'] !== 'string')) ? (routeData['proMetas']['@id'] === proId) : false;
        break;
      default:
        return (routeData[routeData.dataLayerOwnerCheck] && (typeof routeData[routeData.dataLayerOwnerCheck] !== 'string'))
          ? (routeData[routeData.dataLayerOwnerCheck]['@id'] === userId) : false;
    }
    return false;
  }

  public getCurrentContext(): string {
    return this.currentContext;
  }

  public getCurrentAppDataLayer(): AppDataLayer {
    return deepCopy<AppDataLayer>(this.currentAppDataLayer);
  }

  public setCurrentAppDataLayer(dataLayer: AppDataLayer) {
    this.currentAppDataLayer = dataLayer;
    const resetOptions: AppResetOptions = {resetParams: false, resetQueryParams: false, resetProjectType: false, resetProjectTags: false};
    resetOptions.resetParams = !!dataLayer.content.params;
    // resetOptions.resetQueryParams = !!dataLayer.content.queryParams;
    resetOptions.resetProjectType = !!dataLayer.user && !!dataLayer.user.project_type;
    resetOptions.resetProjectTags = !!dataLayer.user && !!dataLayer.user.project_tags;
    // Clean datalayer navigation data (only used for isOwner() method)
    Object.keys(dataLayer.navigation).forEach((nav) => {
      dataLayer.navigation[nav].data = undefined;
    });
    this.pushDataLayer(resetOptions);
  }

  private cleanNullValues(dl: AppDataLayer): AppDataLayer {
    Object.keys(dl.content).forEach(key => !dl.content[key] && delete dl.content[key]);
    if (!!dl.user) {
      Object.keys(dl.user).forEach(key => !dl.user[key] && delete dl.user[key]);
    }
    return dl;
  }

  private pushDataLayer(options?: AppResetOptions) {
    const resetAppDataLayer = this.getCurrentAppDataLayer();
    if (options) {
      if (options.resetParams) {
        resetAppDataLayer.content.params = undefined;
      }
      if (options.resetProjectTags) {
        resetAppDataLayer.user.project_tags = undefined;
      }
      if (options.resetProjectType) {
        resetAppDataLayer.user.project_type = undefined;
      }
    }
    if (!this.isGtmLoaded) {
      this.loadGtm();
      // tslint:disable-next-line:no-string-literal
      window['dataLayer'] = window['dataLayer'] || [];
      if (options) {
        // tslint:disable-next-line:no-string-literal
        window['dataLayer'].push(resetAppDataLayer);
      }
      // tslint:disable-next-line:no-string-literal
      window['dataLayer'].push(this.getCurrentAppDataLayer());
      // tslint:disable-next-line:no-string-literal
      window['dataLayer'].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
    } else {
      // if (options) {
      //   // tslint:disable-next-line:no-string-literal
      //   window['dataLayer'].push(resetAppDataLayer);
      // }
      // tslint:disable-next-line:no-string-literal
      window['dataLayer'].push(this.getCurrentAppDataLayer());
    }
  }

  public sendPageView(options?: { template?: string, title?: string, params?: Params, queryParams?: Params, offset?: number }) {
    if (isPlatformBrowser(this.platformId)) {
      const resetOptions: AppResetOptions = {resetParams: false, resetQueryParams: false};
      if (options) {
        if (typeof options.template !== 'undefined') {
          this.currentAppDataLayer.environment.template = options.template;
        }
        if (typeof options.title !== 'undefined') {
          this.currentAppDataLayer.content.title = options.title;
        }
        if (typeof options.params !== 'undefined') {
          resetOptions.resetParams = !!options.params;
          this.currentAppDataLayer.content.params = options.params;
        }
        // if (typeof options.queryParams !== 'undefined') {
        //   resetOptions.resetQueryParams = !!options.queryParams;
        //   this.currentAppDataLayer.content.queryParams = options.queryParams;
        // }
        if (typeof options.offset !== 'undefined') {
          this.currentAppDataLayer.content.offset = options.offset;
        }
      }
      this.currentAppDataLayer.eventCategory = undefined;
      this.currentAppDataLayer.eventAction = undefined;
      this.currentAppDataLayer.eventLabel = undefined;
      this.pushDataLayer(resetOptions);
      this.sendEvent({event: 'pageView'});
    }
  }

  public sendEvent(options: { event: string, eventCategory?: string, eventAction?: string, eventLabel?: string }) {
    if (isPlatformBrowser(this.platformId) && this.isGtmLoaded) {
      // Avoid multiple "pageView" event to be fired on the same navigation
      if (options.event === 'pageView') {
        const currentPageViewHash = JSON.stringify(this.currentAppDataLayer.navigation || {});
        if (currentPageViewHash === this.previousPageViewHash) {
          return;
        }
        this.previousPageViewHash = currentPageViewHash;
      }

      options.eventCategory = options.eventCategory ? options.eventCategory : undefined;
      options.eventAction = options.eventAction ? options.eventAction : undefined;
      options.eventLabel = options.eventLabel ? options.eventLabel : undefined;
      // tslint:disable-next-line:no-string-literal
      window['dataLayer'].push(options);
    }
  }

  public stopLiveUpdateOfAppDataLayer() {
    if (this.liveUpdateOfAppDataLayerSubscription && !this.liveUpdateOfAppDataLayerSubscription.closed) {
      this.liveUpdateOfAppDataLayerSubscription.unsubscribe();
    }
  }

  private loadGtm() {
    if (this.isGtmLoaded) {
      return;
    }

    const script = document.createElement('script');
    script.id = 'GTMscript';
    script.async = true;
    script.src = '//www.googletagmanager.com/gtm.js?id=' + environment.GTM_ID;
    document.head.insertBefore(script, document.head.lastChild);

    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', '//www.googletagmanager.com/ns.html?id=' + environment.GTM_ID);
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    const noscript = document.createElement('noscript');
    noscript.id = 'GTMiframe';
    noscript.appendChild(iframe);

    document.body.insertBefore(noscript, document.body.firstChild);

    this.isGtmLoaded = true;

    // const doc = this.browserGlobals.documentRef();
    // this.pushOnDataLayer({
    //   'gtm.start': new Date().getTime(),
    //   event: 'gtm.js'
    // });


  }

  public getNavigationDataObs(): Observable<object> {
    return of(this.getNavigationData())
      .pipe(
        switchMap((navigation: object) => {
          const outletObs = {};

          for (const outlet in navigation) {
            if (navigation.hasOwnProperty(outlet) && navigation[outlet].hasOwnProperty('template')) {
              const templateParts = navigation[outlet].template.split('/');
              const templatePartTranslations = templateParts.map((templatePart) => {
                if (templatePart.startsWith(':') || templatePart === '') {
                  return of(templatePart);
                }
                return this.localizedRouter.translatePath(templatePart, 'en');
              });

              if (templatePartTranslations.length > 0) {
                outletObs[outlet] =
                  forkJoin(...templatePartTranslations)
                    .pipe(
                      map((templatePartsTranslated) => templatePartsTranslated.join('/')),
                    );
              }
            }
          }

          if (Object.keys(outletObs).length > 0) {
            return forkJoin(outletObs).pipe(
              map((outletTranslatedTemplates: object) => {
                const translatedNavigation = {...navigation};
                for (const outlet in outletTranslatedTemplates) {
                  if (outletTranslatedTemplates.hasOwnProperty(outlet) && navigation.hasOwnProperty(outlet)) {
                    translatedNavigation[outlet].template = outletTranslatedTemplates[outlet];
                  }
                }

                return translatedNavigation;
              }),
            );
          }

          return of(navigation);
        }),
      )
      ;
  }

  public getNavigationData() {
    const rootRouteData = this.extractRouteData(this.route.root);

    return rootRouteData.reduce((prev, current) => {
      prev[current.outlet] = prev[current.outlet] ?? {
        path: '/',
        template: '/',
        params: {},
        data: {},
      };

      prev[current.outlet] = {
        path: prev[current.outlet].path + (prev[current.outlet].path.endsWith('/') ? '' : '/') + current.path,
        template: prev[current.outlet].template + (prev[current.outlet].template.endsWith('/') ? '' : '/') + current.template,
        params: {...prev[current.outlet].params, ...current.params},
        data: {...prev[current.outlet].data, ...current.data},
      };

      return prev;
    }, {});
  }

  public extractRouteData(route: ActivatedRoute, previousOutlet?: string) {
    const routeData = [];

    // When a child outlet do not have another outlets, its name will be "primary" again, here we need to save
    // the previous outlet to build the tree
    const outlet = route.outlet === 'primary' && previousOutlet ? previousOutlet : route.outlet;
    const template = route.routeConfig ? route.routeConfig.path : '';
    const path = route.snapshot.url.map((segment) => segment.path).join('/');

    routeData.push({
      outlet: outlet,
      template: template,
      path: path,
      params: route.snapshot.params,
      data: route.snapshot.data
    });

    for (const child of route.children) {
      routeData.push(...this.extractRouteData(child, outlet));
    }

    return routeData;
  }

}
