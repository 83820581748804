import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ExternalScriptService {

  private _loadedLibraries: { [url: string]: ReplaySubject<void> } = {};

  constructor(
    @Inject(DOCUMENT) private readonly document: any
  ) {}

  public loadScript(url: string, async = true, defer = true, id: string = null, containerLabel: string = 'body'): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = async;
    script.defer = defer;
    script.src = url;
    if (id) {
      script.id = id;
    }
    script.onload = () => {
      this._loadedLibraries[url].next();
      this._loadedLibraries[url].complete();
    };

    const container = this.document.getElementsByTagName(containerLabel)[0];
    container.appendChild(script);

    return this._loadedLibraries[url].asObservable();
  }

  public loadStyle(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const style = this.document.createElement('link');
    style.type = 'text/css';
    style.href = url;
    style.rel = 'stylesheet';
    style.onload = () => {
      this._loadedLibraries[url].next();
      this._loadedLibraries[url].complete();
    };

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    return this._loadedLibraries[url].asObservable();
  }
}
