import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injector, TemplateRef, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { KzPopup } from '../popup/kz-popup';
import { ContentRef, createContentRef } from '../../utils/content-ref';
import { ModalOptions } from './modal-options';
import { KzModalPopupComponent } from './kz-modal-popup.component';

export class KzModal<T> {
  private _result: any;

  private _contentRef: ContentRef;

  get result(): any {
    return this._result;
  }

  get closed(): boolean {
    return this._popup.closed;
  }

  get opened(): boolean {
    return this._popup.opened;
  }

  get isClosedChanges(): Observable<boolean> {
    return this._popup.isClosedChanges;
  }

  get isOpenedChanges(): Observable<boolean> {
    return this._popup.isOpenedChanges;
  }

  get componentRef(): ComponentRef<T> {
    return this._contentRef?.componentRef;
  }

  constructor(
    private _content: string | TemplateRef<any> | Type<any>,
    private _popup: KzPopup<KzModalPopupComponent>,
    private _options: ModalOptions,
    private _document: Document,
    private _injector: Injector,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _applicationRef: ApplicationRef,
  ) {
  }

  open() {
    const modalInjector = Injector.create({
      providers: [
        {
          provide: KzModal,
          useValue: this,
        },
      ],
      parent: this._injector,
    });
    this._contentRef = createContentRef(
      this._content,
      null,
      this._applicationRef,
      this._document,
      this._componentFactoryResolver,
      modalInjector,
    );
    this._popup.open(this._contentRef);
    this._popup.componentRef.instance.options = this._options;
    this._document.body.style.overflow = 'hidden';
    this.isClosedChanges.pipe(filter((isClosed) => isClosed)).subscribe(() => this._document.body.style.overflow = 'auto');
  }

  get options(): ModalOptions {
    return this._options;
  }

  set options(options: ModalOptions) {
    this._options = options;
    this._popup.componentRef.instance.options = this._options;
  }

  close(result?: any) {
    this._result = result;
    this._popup.close();
  }

  beforeClosed(): Observable<any> {
    return this._popup.isBeforeClosedChanges.pipe(
      filter((isBeforeClosed) => isBeforeClosed),
      take(1),
      map(() => this.result),
    );
  }

  afterClosed(): Observable<any> {
    return this._popup.isClosedChanges.pipe(
      filter((isClosed) => isClosed),
      take(1),
      map(() => this.result),
    );
  }

  afterOpened(): Observable<any> {
    return this._popup.isOpenedChanges.pipe(
      filter((isOpened) => isOpened),
      take(1),
    );
  }
}

// import {
//   ApplicationRef,
//   ComponentRef,
//   TemplateRef,
//   Type,
//   ViewContainerRef
// } from '@angular/core';
// import { Observable } from 'rxjs';
// import { filter, map, take } from 'rxjs/operators';
// import { KzPopup } from '../popup/kz-popup';
// import { ContentRef, createContentRef } from '../../utils/content-ref';
// import { ModalOptions } from './modal-options';
// import { KzModalPopupComponent } from './kz-modal-popup.component';
//
// export class KzModal<T> {
//   private _result: any;
//
//   private _contentRef: ContentRef;
//
//   get result(): any {
//     return this._result;
//   }
//
//   get closed(): boolean {
//     return this._popup.closed;
//   }
//
//   get opened(): boolean {
//     return this._popup.opened;
//   }
//
//   get isClosedChanges(): Observable<boolean> {
//     return this._popup.isClosedChanges;
//   }
//
//   get isOpenedChanges(): Observable<boolean> {
//     return this._popup.isOpenedChanges;
//   }
//
//   get componentRef(): ComponentRef<T> {
//     return this._contentRef?.componentRef;
//   }
//
//   constructor(
//     private _content: string | TemplateRef<any> | Type<any>,
//     private _popup: KzPopup<KzModalPopupComponent>,
//     private _options: ModalOptions,
//     private _document: Document,
//     // private _viewContainerRef: ViewContainerRef,
//     private _applicationRef: ApplicationRef,
//   ) {
//   }
//
//   open() {
//     // // TODO: not used for now
//     // // const modalInjector = Injector.create({
//     // //   providers: [
//     // //     {
//     // //       provide: KzModal,
//     // //       useValue: this,
//     // //     },
//     // //   ],
//     // //   parent: this._injector,
//     // // });
//     // this._contentRef = createContentRef(
//     //   this._content,
//     //   null,
//     //   this._applicationRef,
//     //   this._document,
//     //   this._viewContainerRef,
//     //   // modalInjector,
//     // );
//     // this._popup.open(this._contentRef);
//     // this._popup.componentRef.instance.options = this._options;
//     // this._document.body.style.overflow = 'hidden';
//     // this.isClosedChanges.pipe(filter((isClosed) => isClosed)).subscribe(() => this._document.body.style.overflow = 'auto');
//   }
//
//   get options(): ModalOptions {
//     return this._options;
//   }
//
//   set options(options: ModalOptions) {
//     this._options = options;
//     this._popup.componentRef.instance.options = this._options;
//   }
//
//   close(result?: any) {
//     this._result = result;
//     this._popup.close();
//   }
//
//   beforeClosed(): Observable<any> {
//     return this._popup.isBeforeClosedChanges.pipe(
//       filter((isBeforeClosed) => isBeforeClosed),
//       take(1),
//       map(() => this.result),
//     );
//   }
//
//   afterClosed(): Observable<any> {
//     return this._popup.isClosedChanges.pipe(
//       filter((isClosed) => isClosed),
//       take(1),
//       map(() => this.result),
//     );
//   }
//
//   afterOpened(): Observable<any> {
//     return this._popup.isOpenedChanges.pipe(
//       filter((isOpened) => isOpened),
//       take(1),
//     );
//   }
// }
